<template>
  <div class="pa-4">
    <h1>
      Submitted Assessments List
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Submitted Assessments List"
      color="#2D2D2C"
    >
      <v-data-table
        :items="assessments"
        :headers="headers"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="goToViewPage(item.id)">
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:no-data>
          No assessments found
        </template>
      </v-data-table>
    </base-material-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    id: String
  },
  data() {
    return {
      headers: [
        { text: "Date submitted", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      assessments: [],
      search: "",
      loading: true
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    goToViewPage(id) {
      this.$router.push({ path: "/submitted-assessments/view/" + id });
    },
    async getAssessments() {
      let assessments = await this.$admin.http.get("/api/assessment-responses");
      this.assessments = assessments.data;
    }
  },
  async created() {
    let user = (await this.$admin.authProvider.checkAuth()).data;
    let userRole = user.roles;
    if (userRole !== "admin") {
      this.assessments = user.company.board.assessment_responses;
    } else {
      this.headers.unshift({
        text: "Assessment ID",
        value: "id",
        sortable: true
      });
      this.headers.push({ text: "Company Name", value: "company_name" });
      this.getAssessments();
    }
    this.loading = false;
  }
};
</script>

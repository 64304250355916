<template>
  <v-form ref="form">
    <va-messages></va-messages>
    <v-alert v-if="message" type="error">{{ message }}</v-alert>

    <div class="text-left">
      <router-link class="text-body-4" :to="{ name: 'register_step_2' }">
        &lt; {{ $t("auth.go_back") }}
      </router-link>
    </div>

    <v-text-field
      :label="$t('auth.company_name')"
      prepend-icon="mdi-domain"
      v-model="form.company_name"
      :rules="companyNameRules"
      required
      :error-messages="errorMessages.company_name"
    ></v-text-field>

    <v-select
      :items="possibleJurisdictions"
      item-text="name"
      item-value="uuid"
      :label="$t('auth.jurisdiction')"
      prepend-icon="mdi-earth"
      v-model="form.jurisdiction"
      :error-messages="errorMessages.jurisdiction"
      return-object
      single-line
    ></v-select>

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        @click="saveForm"
        text
        rounded
      >
        {{ $t("auth.next") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    token: String
  },
  data() {
    return {
      possibleJurisdictions: [
        { name: "Ireland", uuid: "IL" },
        { name: "Wales", uuid: "UK-WLS" },
        { name: "Scotland", uuid: "UK-SCT" },
        { name: "Northern Ireland", uuid: "UK-NIR" },
        { name: "England", uuid: "UK-ENG" }
      ],
      form: {
        company_name: null,
        jurisdiction: null
      },
      companyNameRules: [
        value => value !== null || "Company name is required",
        value => {
          if (value) {
            return (
              value.length <= 80 ||
              "Company name must be less than 80 characters"
            );
          }
          return true;
        }
      ],
      errorMessages: {},
      loading: false,
      message: null
    };
  },
  computed: {
    currentForm() {
      return this.$store.getters["forms/currentForm"];
    }
  },
  async mounted() {
    if (this.currentForm) {
      this.form = this.currentForm;
    }
  },
  methods: {
    async saveForm() {
      this.loading = true;
      try {
        await this.$admin.http.post("api/registration/validate", this.form);
        this.$store.dispatch("forms/saveFormData", this.form);
        this.$router.push({ name: "register_step_4" });
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
        }
        this.loading = false;
      }
      this.loading = false;
    }
  },
  components: {}
};
</script>

<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  async mounted() {
    let user = await this.$admin.authProvider.checkAuth();
    if (!user.data.email_verified_at) {
      try {
        if (
          await this.update("put", "/user/profile-information", {
            email_verified_at: new Date()
          })
        ) {
          /**
           * Recheck auth
           */
          this.checkAuth();
          this.$admin.toast.success(this.$t("profile.email_confirmed"));
        }
      } finally {
        await this.$router.push("home");
        this.accountUpdating = false;
      }
    } else {
      await this.$router.push("home");
    }
  },
  methods: {
    ...mapActions({
      checkAuth: "auth/checkAuth"
    }),
    async update(method, url, data) {
      try {
        await this.$admin.http({ method, url, data });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    }
  }
};
</script>

<style scoped></style>

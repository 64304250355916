<template>
  <div class="d-flex flex-column">
    <div class="ml-auto d-flex flex-row">
      <p class="v-label theme--light my-auto mr-3">Archive</p>
      <va-boolean-input class="" v-model="isArchived"> </va-boolean-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArchiveSwitch",
  methods: {},
  computed: {
    isArchived: {
      set() {
        this.$store.dispatch("documents/toggleArchive");
      },
      get() {
        return this.$store.getters["documents/isArchive"];
      }
    }
  }
};
</script>

<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card color="#2D2D2C">
          <template v-slot:heading>
            <div v-if="id" class="display-2">
              {{ $t("resources.documents.titles.edit") }}
            </div>
            <div v-else class="display-2">
              {{ $t("resources.documents.titles.create") }}
            </div>
          </template>
          <v-card-text>
            <va-text-input
              source="name"
              required
              :rules="nameRules"
            ></va-text-input>
            <va-text-input
              source="url"
              required
              :rules="urlRules"
            ></va-text-input>
          </v-card-text>
          <va-save-button text="false" color="#2D2D2C"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data: () => ({
    nameRules: [
      v => (v !== null && v !== undefined) || "Name is required",
      v => (v && v.length <= 40) || "Name must be less than 40 characters"
    ],
    urlRules: [
      v => (v && v.length <= 250) || "Url must be less than 250 characters",
      v => {
        const pattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
        return pattern.test(v) || "Please enter a valid url";
      }
    ]
  })
};
</script>

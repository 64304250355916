<template>
  <div class="pa-4">
    <h1>
      Help Section
    </h1>
    <div class="mt-5" v-if="!loading">
      <va-text-input
        v-model="search"
        placeholder="Search articles"
      ></va-text-input>
      <div class="mt-4" :key="article.id" v-for="article in filteredArticles">
        <h3>{{ article.title }}</h3>
        <p class="mb-0">{{ article.description }}</p>
        <a target="_blank" :href="article.url">{{ article.url }}</a>
        <hr class="mt-2" />
      </div>
      <div class="mt-4" v-if="filteredArticles.length < 1">
        <h3>{{ $t("help-section.none") }}</h3>
      </div>
    </div>
    <div class="mt-5 d-flex" v-else>
      <h2 class="mx-auto">
        {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      helpArticles: [],
      search: "",
      loading: true
    };
  },
  async created() {
    let articles = await this.$admin.dataProvider.getList("help-articles", {});
    this.helpArticles = articles.data;
    this.loading = false;
  },
  computed: {
    filteredArticles: function() {
      return this.helpArticles.filter(article => {
        return article.title.match(this.search);
      });
    }
  }
};
</script>

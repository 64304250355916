<template>
  <div class="pa-4" v-if="!loading">
    <h2 v-if="this.user">
      Hi {{ this.user.first_name ? this.user.first_name : this.user.name }},
      what would you like to do today?
    </h2>
    <cards-menu :links="links"></cards-menu>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import CardsMenu from "../components/CardsMenu.vue";
export default {
  components: { CardsMenu },
  data() {
    return {
      loading: false,
      links: [
        {
          title: "Edit your company profile",
          src: require("../assets/statistics.png"),
          flex: 4,
          url: "/company-profile"
        },
        {
          title: "Edit your personal profile",
          src: require("../assets/filler.png"),
          flex: 4,
          url: "/profile"
        },
        {
          title: "Invite contributors",
          src: require("../assets/documents.png"),
          flex: 4,
          url: "/invite-contributors"
        }
      ]
    };
  }
};
</script>

<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ $t("resources.board-members.titles.show") }}
              </div>
            </template>
            <v-card-text>
              <va-field source="name"></va-field>
              <va-field source="position"></va-field>
              <va-field source="short_bio"></va-field>
              <va-field source="url"></va-field>
              <p class="v-label theme--light mb-0">
                {{ $t("resources.board-members.fields.active") }}
              </p>
              <va-boolean-field source="active"></va-boolean-field>

              <p class="v-label theme--light mt-4">
                {{ $t("resources.board-goals.goals") }}
              </p>
              <v-data-table
                dense
                :headers="headers"
                :items="item.board_goals"
                item-key="description"
                class="elevation-1"
                :items-per-page="5000"
                hide-default-footer
              >
                <template v-slot:item.status="{ item }">
                  <a
                    class="text-decoration-none"
                    :href="'/board-goals/' + item.id"
                  >
                    <v-chip
                      small
                      class="mr-1"
                      color="primary"
                      v-text="item.status"
                      style="background-color: #2b569a"
                    >
                    </v-chip>
                  </a>
                </template>

                <template v-slot:item.description="{ item }">
                  <a
                    class="text-decoration-none"
                    :href="'/board-goals/' + item.id"
                  >
                    {{ item.description }}
                  </a>
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
  data: () => ({
    headers: [
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "status"
      },
      { text: "Description", sortable: false, value: "description" }
    ]
  })
};
</script>

<template>
  <va-form v-if="dataReady" :id="id" :item="localItem">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card color="#2D2D2C">
          <template v-slot:heading>
            <div class="display-2">
              <div v-if="id" class="display-2">
                {{ $t("resources.board-goals.titles.edit") }}
              </div>
              <div v-else class="display-2">
                {{ $t("resources.board-goals.titles.create") }}
              </div>
            </div>
          </template>
          <v-card-text>
            <va-text-input
              :rules="descriptionRules"
              source="description"
              required
            ></va-text-input>
            <va-radio-group-input
              source="status"
              label="Status:"
              row
            ></va-radio-group-input>
            <div v-if="localItem">
              <v-autocomplete
                v-model="localItem.board_members"
                :items="boardMembers"
                model="id"
                item-text="name"
                item-value="id"
                chips
                label="Board Members"
                multiple
              ></v-autocomplete>

              <va-text-input
                class="d-none"
                :key="localItem.board_members.length"
                source="board_members"
              >
              </va-text-input>
            </div>

            <div v-if="localItem">
              <v-combobox
                v-model="localItem.strategic_development_goals"
                :items="strategicDevelopmentGoals"
                model="id"
                item-text="description"
                item-value="id"
                :label="
                  $t(
                    'resources.board-goals.fields.sustainable_development_goals'
                  )
                "
                chips
                multiple
              >
                <template slot="item" slot-scope="data">
                  <img
                    class="mr-2"
                    :src="data.item.logo_url"
                    alt=""
                    width="40px"
                    height="40px"
                  />
                  {{ data.item.id }} - {{ data.item.description }}
                </template>
              </v-combobox>

              <va-text-input
                class="d-none"
                :key="localItem.strategic_development_goals.length"
                source="strategic_development_goals"
              >
              </va-text-input>
            </div>
          </v-card-text>
          <va-save-button text="false" color="#2D2D2C"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      boardMembers: [],
      dataReady: false,
      strategicDevelopmentGoals: [],
      localItem: this.item,
      descriptionRules: [
        v => !!v || "Description is required",
        v =>
          (v && v.length <= 250) ||
          "Description must be less than 250 characters"
      ]
    };
  },
  async mounted() {
    if (this.item === null) {
      this.localItem = {
        id: "",
        description: "",
        status: "",
        strategic_development_goals: "",
        board_members: ""
      };
    }
    let boardMembers = await this.$admin.dataProvider.getList(
      "board-members",
      {}
    );
    if (boardMembers) {
      this.boardMembers = boardMembers.data;
    }
    let sdgs = await this.$admin.dataProvider.getList(
      "strategic-development-goals",
      {}
    );
    if (sdgs) {
      this.strategicDevelopmentGoals = sdgs.data;
    }
    this.dataReady = true;
  }
};
</script>

<template>
  <base-material-card :icon="resource.icon" :title="title" color="#2D2D2C">
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" show-expand disable-clone disable-select>
        <template v-slot:expanded-item="{ item }">
          <div class="sdg-icons">
            <img
              v-for="sdg in item.strategic_development_goals"
              :src="sdg.logo_url"
              alt="sdg"
              :key="sdg.id"
              width="50px"
              height="50px"
            />
          </div>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["description", "status"],
      fields: [
        { source: "description", sortable: true },
        {
          source: "status",
          sortable: true
        },
        {
          source: "board_members",
          type: "referenceArray",
          sortable: false,
          link: "show",
          attributes: {
            reference: "board-members",
            text: "itemText",
            chip: true
          }
        },
        {
          source: "strategic_development_goals",
          type: "referenceArray",
          sortable: false,
          link: "show",
          attributes: {
            reference: "sustainable-development-goals",
            text: "itemText",
            chip: true
          }
        }
      ]
    };
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        e.preventDefault();
      }
    });
  }
};
</script>

<template>
  <div v-if="dataReady">
    <v-row>
      <v-col>
        <form @submit.prevent="updateAccount" class="mb-5">
          <base-material-card
            icon="mdi-account"
            :title="$t('profile.account')"
            color="#2D2D2C"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.first_name')"
                    v-model="accountForm.first_name"
                    required
                    :error-messages="errorMessages.first_name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.last_name')"
                    v-model="accountForm.last_name"
                    required
                    :error-messages="errorMessages.last_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.email')"
                    v-model="accountForm.email"
                    type="email"
                    required
                    :error-messages="errorMessages.email"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.profile_picture')"
                    v-model="accountForm.profile_url"
                    :error-messages="errorMessages.profile_url"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.linkedin_url')"
                    v-model="accountForm.linkedin_url"
                    :error-messages="errorMessages.linkedin_url"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.role')"
                    v-model="accountForm.role"
                    :error-messages="errorMessages.role"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-select-input
                    class="bg-white"
                    :label="$t('profile.gender')"
                    v-model="accountForm.gender"
                    :choices="genderOptions"
                    :error-messages="errorMessages.gender"
                  ></va-select-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-btn
              color="#2D2D2C"
              :loading="accountUpdating"
              class="text-white"
              type="submit"
              >{{ $t("profile.update") }}</v-btn
            >
          </base-material-card>
        </form>
      </v-col>
      <v-col>
        <form @submit.prevent="changePassword">
          <base-material-card
            color="#2D2D2C"
            icon="mdi-lock"
            :title="$t('profile.password')"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.current_password')"
                    type="password"
                    v-model="passwordForm.current_password"
                    required
                    :error-messages="errorMessages.current_password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.password')"
                    type="password"
                    v-model="passwordForm.password"
                    required
                    :error-messages="errorMessages.password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.password_confirmation')"
                    type="password"
                    v-model="passwordForm.password_confirmation"
                    required
                    :error-messages="errorMessages.password_confirmation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-btn
              :loading="passwordChanging"
              class="text-white"
              color="#2D2D2C"
              type="submit"
              >{{ $t("profile.update") }}</v-btn
            >
          </base-material-card>
        </form>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col v-if="!verifiedMail">
        <base-material-card
          color="#2D2D2C"
          icon="mdi-email"
          :title="$t('profile.verify')"
        >
          <v-btn
            href="/verify"
            class="mt-2 text-white"
            color="#2D2D2C"
            type="submit"
          >
            {{ $t("profile.verify_email") }}
          </v-btn>
        </base-material-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      accountUpdating: false,
      passwordChanging: false,
      userRole: null,
      verifiedMail: false,
      dataReady: false,
      accountForm: {
        first_name: null,
        last_name: null,
        email: null,
        profile_url: null,
        linkedin_url: null,
        gender: null,
        role: null
      },
      passwordForm: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      genderOptions: ["Male", "Female", "Non-Binary", "Prefer not to say"],
      errorMessages: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  watch: {
    user: {
      handler(newVal) {
        if (newVal) {
          let { first_name, last_name, email, board_member } = newVal;
          let linkedin_url = board_member.url;
          let profile_url = board_member.profile_url;
          let role = board_member.position;
          let gender = board_member.gender;
          this.accountForm = {
            first_name,
            last_name,
            email,
            profile_url,
            linkedin_url,
            gender,
            role
          };
        }
      },
      immediate: true
    }
  },
  async mounted() {
    let user = await this.$admin.authProvider.checkAuth();
    this.userRole = user.data.roles;
    user.data.email_verified_at != null ? (this.verifiedMail = true) : "";
    this.dataReady = true;
  },
  methods: {
    ...mapActions({
      checkAuth: "auth/checkAuth"
    }),
    async update(method, url, data) {
      try {
        await this.$admin.http({ method, url, data });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    },
    async updateAccount() {
      this.accountUpdating = true;
      try {
        if (
          await this.update(
            "put",
            "/user/profile-information",
            this.accountForm
          )
        ) {
          // /**
          //  * Recheck auth
          //  */
          // this.checkAuth();
          await this.$admin.authProvider.checkAuth();
          this.$admin.toast.success(this.$t("profile.account_updated"));
        }
      } finally {
        this.accountUpdating = false;
      }
    },
    async changePassword() {
      this.passwordChanging = true;

      try {
        if (await this.update("put", "/user/password", this.passwordForm)) {
          /**
           * Reset
           */
          this.passwordForm = {
            current_password: null,
            password: null,
            password_confirmation: null
          };
          this.$admin.toast.success(this.$t("profile.password_changed"));
        }
      } finally {
        this.passwordChanging = false;
      }
    }
  }
};
</script>

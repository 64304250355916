export default (i18n, admin, companyName) => [
  {
    icon: "mdi-domain",
    text: companyName,
    link: "/profiles-dashboard"
  },
  {
    icon: "mdi-home",
    text: i18n.t("menu.home"),
    link: "/home"
  },
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/dashboard"
  },
  {
    icon: "mdi-image-filter-center-focus-strong",
    text: i18n.t("menu.impact-zone"),
    link: "/impact-zone"
  },
  { divider: true },
  admin.can(["admin"]) && {
    icon: "mdi-file-document-alert",
    text: i18n.t("menu.assessments"),
    link: "/assessments"
  },
  admin.can(["admin"]) && {
    icon: "mdi-file-document",
    text: i18n.t("menu.submitted-assessments-admin"),
    link: "/submitted-assessments"
  },
  admin.can(["super_user", "user"]) && {
    icon: "mdi-file-document",
    text: i18n.t("menu.submitted-assessments"),
    link: "/submitted-assessments"
  },
  admin.getResourceLink("companies"),
  admin.getResourceLink("users"),
  admin.getResourceLink("help-articles"),
  admin.getResourceLink("board-members"),
  admin.getResourceLink("board-goals"),
  admin.getResourceLink("sustainable-development-goals"),
  {
    icon: "mdi-file-document",
    text: i18n.t("menu.document-vault"),
    link: "/document-vault"
  },
  admin.getResourceLink("pillars"),
  { divider: true },
  {
    icon: "mdi-head-question",
    text: i18n.t("menu.help"),
    link: "/help"
  }
];

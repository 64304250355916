<template>
  <va-form v-if="dataReady" :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card color="#2D2D2C">
          <template v-slot:heading>
            <div v-if="id" class="display-2">
              {{ $t("resources.board-members.titles.edit") }}
            </div>
            <div v-else class="display-2">
              {{ $t("resources.board-members.titles.create") }}
            </div>
          </template>
          <v-card-text>
            <va-text-input
              source="first_name"
              required
              :rules="nameRules"
            ></va-text-input>
            <va-text-input
              source="last_name"
              required
              :rules="nameRules"
            ></va-text-input>
            <va-text-input
              source="position"
              :rules="positionRules"
            ></va-text-input>
            <va-text-input source="short_bio" :rules="bioRules"></va-text-input>
            <va-text-input source="url" :rules="urlRules"></va-text-input>
            <va-text-input
              source="profile_url"
              :rules="urlRules"
            ></va-text-input>
            <va-select-input source="gender"></va-select-input>
            <va-select-input
              v-if="
                item.user_id &&
                  user &&
                  (user.roles === 'admin' || user.roles === 'super_user')
              "
              source="permissions"
            ></va-select-input>
            <p
              v-else-if="user.roles === 'admin' || user.roles === 'super_user'"
            >
              <small>
                This board member has no corresponding user and cannot have
                permissions set. To be able to set permisions for this board
                member add them as a contributor.
              </small>
            </p>
            <va-boolean-input
              v-model="item.active"
              source="active"
            ></va-boolean-input>
            <va-boolean-input
              v-model="item.is_officer"
              source="is_officer"
            ></va-boolean-input>
          </v-card-text>
          <va-save-button text="false" color="#2D2D2C"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  data: () => ({
    user: null,
    permissions: ["canEdit", "canView"],
    dataReady: false,
    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length <= 40) || "Name must be less than 40 characters"
    ],
    positionRules: [
      v => {
        if (v) {
          return (
            v.length <= 40 || "Company role must be less than 40 characters"
          );
        }
      }
    ],
    bioRules: [
      v => {
        if (v) {
          return (
            v.length <= 100 || "Short bio must be less than 100 characters"
          );
        }
      }
    ],
    urlRules: [
      v => {
        if (v) {
          return v.length <= 200 || "Url must be less than 200 characters";
        }
      },
      v => {
        const pattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
        if (v) {
          return pattern.test(v) || "Please enter a valid url";
        }
        return true;
      }
    ]
  }),
  async mounted() {
    let tempUser = await this.$admin.authProvider.checkAuth();
    this.user = tempUser.data;
    if (this.item !== null) {
      if (this.item.active === 1) {
        this.item.active = Boolean(true);
      } else if (this.item.active === 0) {
        this.item.active = Boolean(false);
      }
      this.item.is_officer =
        this.item.is_officer === 1 ? Boolean(true) : Boolean(false);
    } else {
      this.item = {
        active: Boolean(true),
        is_officer: Boolean(false)
      };
    }
    this.dataReady = true;
  }
};
</script>

<template>
  <v-card class="mx-auto" color="transparent" :outlined="true">
    <v-container>
      <v-row class="mx-5">
        <v-col v-for="link in links" :key="link.title" :cols="link.flex">
          <div class="link-unstyled" @click="redirectTo(link.url)">
            <v-card :hover="true" rounded="xl" class="my-2">
              <v-img
                :src="link.src"
                class="white--text align-end"
                height="200px"
                :contain="true"
              >
              </v-img>
            </v-card>
            <h4 class="text-center my-2">
              {{ link.title }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "CardsMenu",
  props: {
    links: {
      type: Array
    }
  },
  methods: {
    redirectTo(url) {
      this.$router.push({
        path: url
      });
    }
  }
};
</script>

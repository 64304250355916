<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input source="first_name" required></va-text-input>
    <va-text-input source="last_name" required></va-text-input>
    <va-text-input source="email" type="email" required></va-text-input>
    <va-text-input source="company_id" required></va-text-input>
    <va-select-input source="availableRoles" required></va-select-input>
    <va-save-button text="false" color="#2D2D2C"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  methods: {}
};
</script>

<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <users-show v-if="show" :item="item"></users-show>
      <users-form v-else :id="id" :item="item" @saved="onSaved"></users-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title" color="#2D2D2C">
      <va-list
        ref="list"
        disable-export
        disable-create
        disable-create-redirect
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-clone
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
          <!--          <template v-slot:[`item.actions`]="{ resource, item }">-->
          <!--            <impersonate-button-->
          <!--              :resource="resource"-->
          <!--              :item="item"-->
          <!--              icon-->
          <!--            ></impersonate-button>-->
          <!--          </template>-->
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
//import ImpersonateButton from "@/components/buttons/ImpersonateButton";

export default {
  // components: {
  //   ImpersonateButton
  // },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "first_name", sortable: true },
        { source: "last_name", sortable: true },
        { source: "name", sortable: true },
        { source: "email", type: "email", sortable: true },
        { source: "roles", type: "array" },
        { source: "company.name" }
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        e.preventDefault();
      }
    });
  }
};
</script>

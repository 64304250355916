<template>
  <div class="pa-4">
    <h1>
      Assessments List
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments List"
      color="#2D2D2C"
    >
      <v-data-table
        :items="assessments"
        :headers="headers"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-btn
            @click="createAssessment"
            class="ml-auto my-4 theme--dark primary-branding-color"
          >
            New Assessment
          </v-btn>
        </template>
        <template v-slot:item.active="props">
          <v-edit-dialog
            :return-value.sync="props.item.active"
            @save="save(props.item)"
            @cancel="cancel"
            large
          >
            {{ props.item.active }}
            <template v-slot:input>
              <v-switch
                v-model="props.item.active"
                :label="props.item.active ? 'Active' : 'Inactive'"
                counter
              ></v-switch>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="goToEditPage(item.id)">
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:no-data>
          No assessments found
        </template>
      </v-data-table>
    </base-material-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        { text: "Active", value: "active" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      assessments: [],
      search: "",
      loading: true
    };
  },
  methods: {
    goToEditPage(id) {
      this.$router.push({ path: "/assessments/edit/" + id });
    },
    async getAssessments() {
      let assessments = await this.$admin.http.get("/api/assessments");
      this.assessments = assessments.data;
    },
    async createAssessment() {
      try {
        const response = await this.$admin.http.post("/api/assessments");
        this.assessments.push(response.data);
        this.$admin.toast.success("Assessment created");
      } catch (error) {
        this.$admin.toast.error(error.message);
      }
    },
    async save(assessment) {
      try {
        await this.$admin.http.post("/api/assessments/update", {
          assessmentId: assessment.id,
          active: assessment.active
        });
        this.getAssessments();
      } catch (error) {
        this.$admin.toast.error(error.message);
      }
      this.$admin.toast.success("Data saved");
    },
    cancel() {
      this.$admin.toast.error("Canceled");
    }
  },
  async created() {
    this.getAssessments();
    this.loading = false;
  }
};
</script>

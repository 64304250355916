<template>
  <base-material-card :icon="resource.icon" :title="title" color="#2D2D2C">
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" disable-clone>
        <template v-slot:[`field.profile_url`]="{ value }">
          <img
            v-if="value"
            style="border-radius: 50%; margin-top: 0.3rem;"
            width="75px"
            height="75px"
            :src="value"
            alt=""
          />
          <img
            v-else
            style="border-radius: 50%; margin-top: 0.3rem;"
            width="75px"
            height="75px"
            src="../../assets/no-avatar.png"
            alt=""
          />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["name", "position"],
      fields: [
        { source: "profile_url", label: "Profile Picture" },
        { source: "first_name", sortable: true },
        { source: "last_name", sortable: true },
        { source: "name", sortable: true },
        { source: "position", sortable: true },
        { source: "short_bio", sortable: true },
        { source: "url", type: "url" },
        { source: "active", type: "boolean", sortable: true },
        { source: "gender", label: "Gender" },
        {
          source: "is_officer",
          type: "boolean",
          sortable: true,
          label: "Is officer"
        }
      ]
    };
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        e.preventDefault();
      }
    });
  }
};
</script>

<template>
  <div v-if="itemsReady && !loading">
    <HelpButton></HelpButton>
    <v-row v-if="itemsReady">
      <!--      PDF Export Component-->
      <pdf-export :data="data" :items="items" ref="html2Pdf"> </pdf-export>

      <!--        Period selector and export pdf button -->
      <template>
        <v-container fluid>
          <v-row v-if="data.overall_score" align="center">
            <v-col cols="6">
              <v-subheader>
                {{ $t("dashboard.select_period") }}
              </v-subheader>
            </v-col>
            <v-col cols="4" class="text-right">
              <!--              <select @change="changeSelectedPeriod" v-model="select">-->
              <!--                <option-->
              <!--                  :key="period.id"-->
              <!--                  v-for="period in data.board.assessment_periods"-->
              <!--                  :value="period.id"-->
              <!--                  >{{ period.start_date }}</option-->
              <!--                >-->
              <!--              </select>-->
              <v-select
                v-model="select"
                :items="data.board.assessment_periods"
                item-text="start_date"
                item-value="id"
                label="Select"
                hint="Assessment period start date"
                v-on:change="changeSelectedPeriod"
                return-object
                persistent-hint
                single-line
              ></v-select>
            </v-col>
            <v-col cols="2" class="mw-100 text-right">
              <v-btn class="" @click="generateReport">
                {{ $t("dashboard.export_report") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!--      Company information and overall score gauge-->
      <v-col v-if="data.overall_score" cols="12" class="accent pa-6">
        <v-row>
          <!--          Company information-->
          <v-col cols="12" md="6">
            <h2 class="mb-2 text-h2 font-weight-bold">{{ data.name }}</h2>
            <p
              v-if="data.company_slogan"
              class="text-h3 mb-8 font-weight-light"
            >
              {{ data.company_slogan }}
            </p>
            <div
              v-if="data.overall_score"
              class="pt-5 px-4 pb-2 primary-branding-color mt-8 rounded rounded d-flex flex-column align-center"
              style="width: 120px;"
            >
              <img
                src="../assets/boards-123-header-logo.jpg"
                width="80"
                height="67"
                alt=""
              />
              <span class="text-uppercase white--text d-block mt-3">{{
                $t("dashboard.assessed")
              }}</span>
            </div>
            <v-btn
              v-if="data.overall_score"
              color="#2D2D2C"
              class="text-white mt-4"
              @click="redirectTo('/assessment')"
              type="submit"
              >{{ $t("dashboard.retake_maturity_assessment_btn") }}
            </v-btn>
            <!-- If the company doesn't have an overall score(hasn't completed assessment) -->
            <div
              v-if="
                !data.overall_score &&
                  (userRole === 'super_user' || userRole === 'admin')
              "
            >
              <!-- <h3 class="mb-2 text-h2 font-weight-bold">Your Maturity Assessment </h3> -->
              <v-btn
                color="#2D2D2C"
                class="text-white"
                @click="redirectTo('/assessment')"
                type="submit"
                >{{ $t("dashboard.maturity_assessment_btn") }}
              </v-btn>
            </div>
            <div v-else-if="!data.overall_score && userRole === 'user'">
              <h3 class="mb-2 text-h2 font-weight-bold">
                {{ $t("dashboard.needs-privilege") }}
              </h3>
            </div>
          </v-col>
          <!--          Overall score gauge and level explanation -->
          <v-col cols="12" md="6" v-if="data.overall_score">
            <v-card class="pa-8" color="elevation-0">
              <div class="overall-score--gauge">
                <vue-svg-gauge
                  :start-angle="-90"
                  :end-angle="90"
                  :value="data.overall_score"
                  :separator-step="25"
                  :inner-radius="80"
                  :min="0"
                  :max="125"
                  :gauge-color="[
                    { offset: 0, color: '#F2A900' },
                    { offset: 25, color: '#F9C84D' },
                    { offset: 50, color: '#F8F353' },
                    { offset: 75, color: '#78F367' },
                    { offset: 100, color: '#126CB5' }
                  ]"
                  base-color="#d0cdcd"
                />
                <p class="overall-score--label">
                  {{ data.overall_score }}<span class="grey--text">/125</span>
                </p>
              </div>
              <p class="overall-score--level">
                {{ $t("dashboard.initial_maturity_level") }} :

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="data.overall_score <= 25"
                      v-bind="attrs"
                      v-on="on"
                      class="grey--text"
                      >{{ $t("dashboard.level_starter") }}
                      <v-icon x-small>
                        mdi-help-circle
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t("dashboard.level_starter_desc") }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="data.overall_score > 25 && data.overall_score <= 50"
                      v-bind="attrs"
                      v-on="on"
                      class="grey--text"
                      >{{ $t("dashboard.level_developing") }}
                      <v-icon x-small>
                        mdi-help-circle
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t("dashboard.level_developing_desc") }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="data.overall_score > 50 && data.overall_score <= 75"
                      v-bind="attrs"
                      v-on="on"
                      class="grey--text"
                      >{{ $t("dashboard.level_consistent") }}
                      <v-icon x-small>
                        mdi-help-circle
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t("dashboard.level_consistent_desc") }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="
                        data.overall_score > 75 && data.overall_score <= 100
                      "
                      v-bind="attrs"
                      v-on="on"
                      class="grey--text"
                      >{{ $t("dashboard.level_achieving") }}
                      <v-icon x-small>
                        mdi-help-circle
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t("dashboard.level_achieving_desc") }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-if="data.overall_score > 100"
                      v-bind="attrs"
                      v-on="on"
                      class="grey--text"
                      >{{ $t("dashboard.level_leading") }}
                      <v-icon x-small>
                        mdi-help-circle
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t("dashboard.level_leading_desc") }}
                  </span>
                </v-tooltip>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <div v-else class="col-12">
        <cards-menu :links="links"></cards-menu>
      </div>

      <!--      Pillar scores and goals-->
      <v-col cols="12" class="pa-6" v-if="data.overall_score">
        <v-row>
          <!--          Scores per pillar-->
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="mb-2 text-h2 font-weight-bold">
                  {{ $t("dashboard.pillars_lbl") }}
                </h2>
              </v-col>
              <v-col class="pillar-score" cols="12" md="12" lg="6">
                <base-material-stats-card
                  icon="mdi-account-supervisor-circle"
                  title="People Score"
                  color="#2D2D2C"
                  :value="data.people_score.toString() + ' / 25'"
                />
              </v-col>
              <v-col class="pillar-score" cols="12" md="12" lg="6">
                <base-material-stats-card
                  icon="mdi-earth"
                  title="Environment Score"
                  color="#2D2D2C"
                  :value="data.environment_score.toString() + ' / 25'"
                />
              </v-col>
              <v-col class="pillar-score" cols="12" md="12" lg="6">
                <base-material-stats-card
                  icon="mdi-file-document-outline"
                  title="Governance Score"
                  color="#2D2D2C"
                  :value="data.governance_score.toString() + ' / 50'"
                />
              </v-col>
              <v-col class="pillar-score" cols="12" md="12" lg="6">
                <base-material-stats-card
                  icon="mdi-hand-heart-outline"
                  title="Social Impact Score"
                  color="#2D2D2C"
                  :value="data.social_impact_score.toString() + ' / 25'"
                />
              </v-col>
            </v-row>
          </v-col>

          <!--          Chart showing pillar scores-->
          <v-col v-if="showChart" cols="12" md="6">
            <base-material-chart-card
              :data="companyScoresChart.data"
              :options="companyScoresChart.options"
              color="#F2A900"
              class="primary--text-1"
              type="Bar"
              ref="chart"
            >
            </base-material-chart-card>
          </v-col>

          <!--Call to action-->
          <v-col cols="12">
            <v-alert
              prominent
              type="success"
              icon="mdi-information-outline"
              color="#F2A900"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ $t("dashboard.contact_boards123") }}
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    href="https://www.boards123.com/book"
                    target="_blank"
                    color="#2D2D2C"
                  >
                    {{ $t("dashboard.find_more") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>

          <!--          Goals list using the board goals component-->
          <v-col cols="12">
            <base-material-tabs-card :tabs="tabs" color="#2D2D2C">
              <template v-slot:1>
                <board-goal-headers v-if="items[4].goals.length > 0">
                </board-goal-headers>

                <div :key="i" v-for="(item, i) in items[4].goals">
                  <board-goal-item :itemIndex="i" :item="item">
                  </board-goal-item>
                </div>
                <div class="row my-2" v-if="!items[4].goals.length > 0">
                  <h2 class="ml-2 my-auto">{{ $t("dashboard.no-goals") }}</h2>
                  <v-chip class="help-button ml-3 my-auto" color="#2D2D2C">
                    <a
                      class="text-white"
                      @click="redirectTo('/board-goals/create')"
                    >
                      {{ $t("dashboard.create-goal") }}
                    </a>
                  </v-chip>
                </div>
                <!-- 1st tab content -->
              </template>

              <template v-slot:2>
                <board-goal-headers v-if="items[1].goals.length > 0">
                </board-goal-headers>

                <div :key="i" v-for="(item, i) in items[1].goals">
                  <board-goal-item :itemIndex="i" :item="item">
                  </board-goal-item>
                </div>

                <div class="row my-2" v-if="!items[1].goals.length > 0">
                  <h2 class="ml-2 my-auto">{{ $t("dashboard.no-goals") }}</h2>
                  <v-chip class="help-button ml-3 my-auto" color="#2D2D2C">
                    <a
                      class="text-white"
                      @click="redirectTo('/board-goals/create')"
                    >
                      {{ $t("dashboard.create-goal") }}
                    </a>
                  </v-chip>
                </div>
                <!-- 2nd tab content -->
              </template>
              <template v-slot:3>
                <board-goal-headers v-if="items[2].goals.length > 0">
                </board-goal-headers>

                <div :key="i" v-for="(item, i) in items[2].goals">
                  <board-goal-item :itemIndex="i" :item="item">
                  </board-goal-item>
                </div>

                <div class="row my-2" v-if="!items[2].goals.length > 0">
                  <h2 class="ml-2 my-auto">{{ $t("dashboard.no-goals") }}</h2>
                  <v-chip class="help-button ml-3 my-auto" color="#2D2D2C">
                    <a
                      class="text-white"
                      @click="redirectTo('/board-goals/create')"
                    >
                      {{ $t("dashboard.create-goal") }}
                    </a>
                  </v-chip>
                </div>
                <!-- 3rd tab content -->
              </template>
              <template v-slot:4>
                <board-goal-headers v-if="items[3].goals.length > 0">
                </board-goal-headers>

                <div :key="i" v-for="(item, i) in items[3].goals">
                  <board-goal-item :itemIndex="i" :item="item">
                  </board-goal-item>
                </div>

                <div class="row my-2" v-if="!items[3].goals.length > 0">
                  <h2 class="ml-2 my-auto">{{ $t("dashboard.no-goals") }}</h2>
                  <v-chip class="help-button ml-3 my-auto" color="#2D2D2C">
                    <a
                      class="text-white"
                      @click="redirectTo('/board-goals/create')"
                    >
                      {{ $t("dashboard.create-goal") }}
                    </a>
                  </v-chip>
                </div>
                <!-- 4rd tab content -->
              </template>

              <template v-slot:5>
                <board-goal-headers v-if="items[0].goals.length > 0">
                </board-goal-headers>

                <div :key="i" v-for="(item, i) in items[0].goals">
                  <board-goal-item :itemIndex="i" :item="item">
                  </board-goal-item>
                </div>

                <div class="row my-2" v-if="!items[0].goals.length > 0">
                  <h2 class="ml-2 my-auto">{{ $t("dashboard.no-goals") }}</h2>
                  <v-chip class="help-button ml-3 my-auto" color="#2D2D2C">
                    <a
                      class="text-white"
                      @click="redirectTo('/board-goals/create')"
                    >
                      {{ $t("dashboard.create-goal") }}
                    </a>
                  </v-chip>
                </div>
                <!-- 5th tab content -->
              </template>
            </base-material-tabs-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Key submission dates -->
      <v-col class="mx-5" cols="12" v-if="data.company_number">
        <div class="d-flex" v-if="data.last_accounts_date">
          <div class="mr-2 d-flex">
            <img
              width="25"
              height="25"
              src="../assets/checkmark-green.png"
              class="mr-2 display-2 color-green my-auto ml-1"
            />
          </div>
          <h2 class="mr-2">
            {{ $t("dashboard.last-accounts") }}
          </h2>
          <h2>{{ data.last_accounts_date }}</h2>
        </div>
        <div class="d-flex" v-if="data.next_accounts_date">
          <div class="mr-2 d-flex">
            <img
              width="25"
              height="25"
              src="../assets/overdue.png"
              class="my-auto ml-1 mr-2"
              :key="data.next_accounts_overdue"
              v-if="data.next_accounts_overdue"
            />
            <img
              v-else
              width="25"
              height="25"
              src="../assets/due.png"
              class="ml-1 my-auto mr-2"
            />
          </div>
          <h2 class="mr-2">
            {{ $t("dashboard.next-accounts") }}
          </h2>
          <h2>
            {{ data.next_accounts_date }}
          </h2>
        </div>
      </v-col>
      <v-col class="pa-6" v-else>
        <v-alert
          prominent
          type="success"
          outlined
          icon="mdi-information-outline"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t("dashboard.company_number_message") }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="redirectTo('/company-profile')">{{
                $t("dashboard.click_here")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import { VueSvgGauge } from "vue-svg-gauge";
import BoardGoalItem from "@/components/BoardGoalItem";
import PdfExport from "@/components/PdfExport";
import BoardGoalHeaders from "@/components/BoardGoalHeaders";
import HelpButton from "@/components/buttons/HelpButton";
import CardsMenu from "../components/CardsMenu.vue";

export default {
  data() {
    return {
      showChart: true,
      links: [
        {
          title: "Take the ESG Assessment",
          src: require("../assets/fill-out-document.png"),
          flex: 4,
          target: "_blank",
          url: "/assessment"
        },
        {
          title: "Add board goals",
          src: require("../assets/filler.png"),
          flex: 4,
          url: "/board-goals"
        },
        {
          title: "Add board members",
          src: require("../assets/documents.png"),
          flex: 4,
          url: "/board-members"
        },
        {
          title: "Get help",
          src: require("../assets/filler.png"),
          flex: 4,
          url: "/help-articles"
        }
      ],
      companysChartData: {
        labels: [
          "People Score",
          "Environment Score",
          "Governance Score",
          "Social Impact Score"
        ],
        series: [[50, 75, 40, 11]]
      },
      companiesChartOptions: {
        options: {
          showArea: true,
          chartPadding: {
            left: 0,
            bottom: 15
          },
          // axisX: {},
          // axisY: {
          //   offset: 70,
          //   scaleMinSpace: 30,
          //   labelInterpolationFnc: function(value) {
          //     return value + " %";
          //   }
          // },
          high: 100,
          low: 0
        }
      },
      loading: true,
      select: { start_date: "", id: "" },
      data: null,
      companyScoresChart: [],
      items: [],
      userRole: null,
      itemsReady: false,
      list: {
        0: false,
        1: false,
        2: false
      },
      tabs: [
        {
          id: 1,
          label: "All",
          icon: " mdi-check-all"
        },
        {
          id: 2,
          label: "Environment",
          icon: "mdi-earth",
          href: "null"
        },
        {
          id: 3,
          label: "Social Impact",
          icon: "mdi-hand-heart-outline"
        },
        {
          id: 4,
          label: "People",
          icon: "mdi-account-supervisor-circle"
        },
        {
          id: 5,
          label: "Governance",
          icon: "mdi-file-document-outline"
        }
      ]
    };
  },
  async mounted() {
    /* Get currently authenticated user */
    let user = await this.$admin.authProvider.checkAuth();
    this.initPendo(user);
    this.userRole = user.data.roles;
    let temp = null;
    /**
     * Use the global vuex store instance.
     * You need to provide the name of the resource followed by the provider method you want to call.
     *
     * Get an instance of the user company
     */
    if (user.data.company_id) {
      temp = await this.$store.dispatch("companies/getOne", {
        id: user.data.company_id
      });
    }
    /* Get a list of the board goals if company exists */
    if (temp && temp.data.activePeriod.responses.length > 0) {
      let goals = await this.$admin.dataProvider.getList("board-goals", {
        include: [
          "strategic_development_goals",
          "strategic_development_goals.media"
        ]
      });

      let pillars = await this.$admin.dataProvider.getList("pillars", {});
      this.pillars = pillars.data;
      this.data = temp.data;

      /* Get the scores for the given period */
      let periodsArray = temp.data.board.assessment_periods;
      for (var i = 0; i < periodsArray.length; i++) {
        if (periodsArray[i].id === temp.data.activePeriod.id) {
          this.getScoresForSelectedPeriod(periodsArray[i]);
        }
      }

      /* Add the goal items to an array */
      this.addItems(goals.data);

      /* Set selectedPeriod to the active period by default */
      this.data.selectedPeriod = temp.data.activePeriod;
      this.select = {
        start_date: temp.data.activePeriod.start_date,
        id: temp.data.activePeriod.id
      };

      /* Populate the company scores chart */
      this.companyScoresChart = {
        data: {
          labels: [
            "People Score",
            "Environment Score",
            "Governance Score",
            "Social Impact Score"
          ],
          series: [
            [
              (this.data.people_score / 25) * 100,
              (this.data.environment_score / 25) * 100,
              (this.data.governance_score / 50) * 100,
              (this.data.social_impact_score / 25) * 100
            ]
          ]
        },
        options: {
          fullWidth: true,
          showArea: true,
          chartPadding: {
            left: 0,
            bottom: 15,
            top: 25
          },
          axisX: {},
          axisY: {
            offset: 70,
            scaleMinSpace: 35,
            labelInterpolationFnc: function(value) {
              return value + " %";
            }
          },
          high: 100,
          low: 0
        }
      };
    } else {
      this.data = { overall_score: null };
    }
    this.itemsReady = true;
    this.loading = false;
  },
  methods: {
    redirectTo(url) {
      this.$router.push({
        path: url
      });
    },
    initPendo(user) {
      if (user) {
        (function(apiKey) {
          (function(p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = [
              "initialize",
              "identify",
              "updateOptions",
              "pageLoad",
              "track"
            ];
            for (w = 0, x = v.length; w < x; ++w)
              (function(m) {
                o[m] =
                  o[m] ||
                  function() {
                    o._q[m === v[0] ? "unshift" : "push"](
                      [m].concat([].slice.call(arguments, 0))
                    );
                  };
              })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
          })(window, document, "script", "pendo");
          let pendo = window.pendo;
          // Call this whenever information about your visitors becomes available
          // Please use Strings, Numbers, or Bools for value types.
          pendo.initialize({
            visitor: {
              id: user.data.id, // Required if user is logged in
              email: user.data.email // Recommended if using Pendo Feedback, or NPS Email
              // full_name:    // Recommended if using Pendo Feedback
              // role:         // Optional

              // You can add any additional visitor level key-values here,
              // as long as it's not one of the above reserved names.
            },

            account: {
              id: user.data.id, // Highly recommended
              // name:         // Optional
              // is_paying:    // Recommended if using Pendo Feedback
              // monthly_value:// Recommended if using Pendo Feedback
              // planLevel:    // Optional
              // planPrice:    // Optional
              creationDate: user.data.created_at

              // You can add any additional account level key-values here,
              // as long as it's not one of the above reserved names.
            }
          });
        })("ca050557-0307-4fd0-6bc6-58a23b465dee");
      }
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },

    /* Process the change of selected period */
    async changeSelectedPeriod(a) {
      this.showChart = false;
      await this.getScoresForSelectedPeriod(a);
      await this.addItems(a.goals);
      this.companyScoresChart.data.series = [
        [
          (this.data.people_score / 25) * 100,
          (this.data.environment_score / 25) * 100,
          (this.data.governance_score / 50) * 100,
          (this.data.social_impact_score / 25) * 100
        ]
      ];
      this.showChart = true;
    },

    /* Put goals into an array with their corresponding pillar */
    addItems(goals) {
      let itemsArray = [];
      /* Loop through pillars and create an array with the goals belonging to them */
      for (var n = 0; n < this.pillars.length; n++) {
        let item = [];
        item["goals"] = [];

        item["pillar_id"] = this.pillars[n]["id"];
        if (this.pillars[n]["id"] !== 5) {
          for (var i = 0; i < goals.length; i++) {
            for (
              var m = 0;
              m < goals[i]["strategic_development_goals"].length;
              m++
            ) {
              /* Check if goal belongs to current pillar and add it to array */
              if (
                this.pillars[n]["id"] ===
                goals[i]["strategic_development_goals"][m]["pillar_id"]
              ) {
                if (item["goals"].length === 0) {
                  item["goals"].push(goals[i]);
                } else if (
                  Object.values(item["goals"]).indexOf(goals[i]) === -1
                ) {
                  item["goals"].push(goals[i]);
                }
              }
            }
          }
        } else {
          item["goals"] = goals;
        }
        itemsArray.push(item);
      }
      this.items = itemsArray;
      this.itemsReady = true;
    },

    /* Get the correct score for each pillar */
    getScoresForSelectedPeriod(period) {
      this.data.selectedPeriod = period;
      var scores = period.responses[period.responses.length - 1].scores;

      if (scores.length) {
        for (let i = 0; i < scores.length; i++) {
          switch (scores[i].pillar.name) {
            case "governance":
              this.data.governance_score = scores[i].score;
              break;
            case "environment":
              this.data.environment_score = scores[i].score;
              break;
            case "social-impact":
              this.data.social_impact_score = scores[i].score;
              break;
            case "people":
              this.data.people_score = scores[i].score;
              break;
            default:
              this.data.overall_score = scores[i].score;
              break;
          }
        }
      } else {
        this.data.governance_score = 0;
        this.data.environment_score = 0;
        this.data.social_impact_score = 0;
        this.data.people_score = 0;
        this.data.overall_score = 0;
      }
    },
    generateReport() {
      this.$refs.html2Pdf.$refs.html2Pdf.generatePdf();
    }
  },
  components: {
    HelpButton,
    PdfExport,
    VueSvgGauge,
    BoardGoalItem,
    BoardGoalHeaders,
    CardsMenu
  }
};
</script>

<style lang="sass">
.overall-score--gauge
  position: relative

.overall-score--label
  position: absolute
  width: 100%
  text-align: center
  font-size: 4rem
  left: 0
  bottom: 10px
  margin: 0!important
  line-height: 100%
  font-weight: 600

.overall-score--label .grey--text
  font-size: 3rem
  font-weight: 400

.overall-score--level
  width: 100%
  text-align: center
  font-size: 1.3em
  line-height: 100%
  margin-top: 1em
  margin-bottom: 0!important
  display: block

.overall-score--radial
   font-size: 5rem
   border: 20px solid #0A2A54 !important
   background-color: #eceff1 !important
   max-width: 70% !important
   margin: 0 auto

.task-list-item
  border-bottom: 1px solid #eceff1!important

.ct-chart-bar .ct-bar
  stroke-width: 2rem

.pillar-score
  flex: 0 0 100%
  max-width: 100%
  @media (min-width: 1400px)
    flex: 0 0 50%!important
    max-width: 50%!important
</style>

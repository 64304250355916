<template>
  <div v-if="!loading && company">
    <HelpButton></HelpButton>
    <div class="mt-5">
      <cards-menu :links="documentCategories"></cards-menu>
    </div>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
import CardsMenu from "../components/CardsMenu.vue";
export default {
  components: {
    HelpButton,
    CardsMenu
  },
  data() {
    return {
      loading: false,
      company: null,
      documentCategories: [
        {
          title: "Governing Documents",
          src: require("../assets/GoverningDocs.svg"),
          flex: 4,
          url: "/document-vault/governing-docs"
        },
        {
          title: "AGM & Annual Reports",
          src: require("../assets/AGMAnnualReports.svg"),
          flex: 4,
          url: "/document-vault/agm-annual-trustee-reports"
        },
        {
          title: "Legal, Insurance & Regulation",
          src: require("../assets/LegalInsuranceRegulation.svg"),
          flex: 4,
          url: "/document-vault/legal-insurance-regulation-docs"
        },
        {
          title: "Onboarding Pack",
          src: require("../assets/OnboardingPack.svg"),
          flex: 4,
          url: "/document-vault/onboarding-docs"
        },
        {
          title: "Board & Sub-committee papers",
          src: require("../assets/BoardSub-CommitteePapers.svg"),
          flex: 4,
          url: "/document-vault/board-subcommittee-docs"
        },
        {
          title: "Policies",
          src: require("../assets/Policies.svg"),
          flex: 4,
          url: "/document-vault/policy-docs"
        }
      ]
    };
  },
  async mounted() {
    this.loading = true;
    let user = await this.$admin.authProvider.checkAuth();
    if (user.data.company_id) {
      let tempCompany = await this.$store.dispatch("companies/getOne", {
        id: user.data.company_id
      });
      this.company = tempCompany.data;
    }
    this.loading = false;
  },
  methods: {}
};
</script>

<template>
  <v-form ref="form">
    <va-messages></va-messages>
    <v-alert v-if="message" type="error">{{ message }}</v-alert>

    <div class="text-left">
      <router-link
        v-if="userCreated"
        class="text-body-4"
        :to="{ name: 'home' }"
      >
        &lt; {{ $t("auth.go_to_dashboard") }}
      </router-link>
      <router-link v-else class="text-body-4" :to="{ name: 'register_step_3' }">
        &lt; {{ $t("auth.go_back") }}
      </router-link>
    </div>

    <v-text-field
      :label="$t('auth.company_number')"
      prepend-icon="mdi-pound-box"
      :rules="companyNumberRules"
      v-model="form.company_number"
      :error-messages="errorMessages.company_number"
    ></v-text-field>

    <v-menu
      v-model="assessment_period_end_menu"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="$t('auth.assessment_period_end')"
          v-model="form.assessment_period_end"
          required
          readonly
          :error-messages="errorMessages.assessment_period_end"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="form.assessment_period_end"
        :error-messages="errorMessages.assessment_period_end"
        @input="assessment_period_end_menu = false"
        :min="tomorrow"
      ></v-date-picker>
    </v-menu>
    <p class="mt-2 caption">
      Adding your company number would allow us to get company information like
      important due dates and officer information from Companies House. Don't
      worry you can add this later in your company profile.
    </p>

    <terms-checkbox :dialog="dialog" :form="form"> </terms-checkbox>

    <div class="d-flex">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loading"
            color="primary"
            large
            v-bind="attrs"
            v-on="on"
            @click="register"
            text
            rounded
          >
            {{ $t("auth.skip") }}</v-btn
          >
        </template>
        <span>{{ $t("auth.skip-tooltip") }}</span>
      </v-tooltip>
      <v-btn
        :loading="loading"
        class="ml-auto"
        color="primary"
        large
        @click="register"
        text
        rounded
      >
        {{ $t("auth.next") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import TermsCheckbox from "@/components/TermsCheckbox";
import moment from "moment";
export default {
  props: {
    token: String
  },
  data() {
    return {
      dialog: false,
      form: {
        company_number: null,
        assessment_period_end: null,
        today: new Date().toLocaleDateString(),
        acceptTerms: false
      },
      companyNumberRules: [
        value => {
          if (value) {
            return (
              value.length <= 8 ||
              "Company number must be less than 9 characters"
            );
          } else {
            return true;
          }
        },
        value => {
          const pattern1 = /[a-zA-Z]{2}[0-9]{6}/;
          const pattern2 = /[a-zA-Z]{1}[0-9]{7}/;
          const pattern3 = /[0-9]{8}/;
          if (value) {
            return (
              pattern1.test(value) ||
              pattern2.test(value) ||
              pattern3.test(value) ||
              "Invalid company number"
            );
          }
          return true;
        }
      ],
      assessment_period_end_menu: false,
      tomorrow: null,
      errorMessages: {},
      loading: false,
      message: null,
      userCreated: false
    };
  },
  computed: {
    currentForm() {
      return this.$store.getters["forms/currentForm"];
    }
  },
  async mounted() {
    if (this.currentForm) {
      this.form = this.currentForm;
    }
    let myDate = new Date();

    this.tomorrow =
      myDate.getFullYear() +
      "-" +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (myDate.getDate() + 1)).slice(-2);
  },
  methods: {
    async register() {
      this.loading = true;
      if (!this.form.assessment_period_end) {
        this.form.assessment_period_end = moment()
          .add(1, "year")
          .format("YYYY-MM-DD");
      }
      try {
        await this.$admin.http.post("api/registration/validate", this.form);
        await this.$admin.http.post("/register", this.form);
        this.$admin.toast.success(this.$t("profile.successfully_created"));
        this.$router.push({ name: "home" });
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
          return;
        }
        this.message = e.message;
      } finally {
        this.userCreated = true;
        this.loading = false;
        this.$store.dispatch("forms/clearFormData", this.form);
      }
    }
  },
  components: {
    TermsCheckbox
  }
};
</script>

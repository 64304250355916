<template>
  <div class="d-flex flex-column">
    <v-chip
      small
      class="help-button mb-2 mr-1 ml-auto"
      @click="goToHelp()"
      color="#2D2D2C"
    >
      <a class="text-white">
        {{ $t("impact-zone.help") }}
      </a>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "HelpButton",
  methods: {
    goToHelp() {
      this.$router.push({
        path: "/help"
      });
    }
  }
};
</script>

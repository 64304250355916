<template>
  <div v-if="!loading">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <HelpButton class="mt-4"></HelpButton>
    <div class="mt-5">
      <v-btn
        v-if="currentDocument.file_path"
        @click.stop="downloadDocument"
        prepend-icon="mdi-download"
        class="ml-auto my-4 mr-2 theme--dark blue-branding-color"
      >
        <v-icon class="mr-1" dark>
          mdi-download
        </v-icon>
        Download
      </v-btn>
      <v-btn
        v-if="canEdit && !currentDocument.is_archived"
        @click="editDocument"
        class="ml-auto my-4 mr-2 theme--dark yellow-branding-color"
      >
        <v-icon class="mr-1" dark>
          mdi-pencil
        </v-icon>
        Edit
      </v-btn>
      <v-btn
        @click="dialogOpen = true"
        v-if="canDelete"
        color=""
        class="ml-auto my-4 mr-2 theme--dark red-branding-color"
      >
        <v-icon class="mr-1" dark>
          mdi-delete
        </v-icon>
        Delete
      </v-btn>
      <v-btn
        v-if="canEdit && !currentDocument.is_archived"
        @click="archiveDocument"
        class="ml-auto my-4 theme--dark primary-branding-color"
      >
        <v-icon class="mr-1" dark>
          mdi-archive
        </v-icon>
        Archive
      </v-btn>
      <h1>{{ currentDocument.name }}</h1>
      <p>{{ currentDocument.description }}</p>
      <a v-if="currentDocument.url" :href="currentDocument.url">{{
        currentDocument.url
      }}</a>
      <p v-if="requiredDocument">
        For required document: {{ requiredDocument.name }}
      </p>
    </div>
    <v-dialog v-model="dialogOpen" max-width="290">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this document?</v-card-title
        >

        <v-card-text class="text-center">
          <small>
            Clicking "Agree" would delete this document and any related file.
            Deletion is non-reversable and permanent.
          </small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="theme--dark primary-branding-color"
            flat="flat"
            @click="dialogOpen = false"
          >
            Disagree
          </v-btn>

          <v-btn
            color="theme--dark primary-branding-color"
            flat="flat"
            @click="deleteDocument()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
import i18n from "@/i18n";

export default {
  components: {
    HelpButton
  },
  data() {
    return {
      loading: false,
      company: null,
      docs: [],
      canEdit: false,
      canDelete: false,
      dialogOpen: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: "Submitted", value: "is_submitted" }
      ]
    };
  },
  computed: {
    currentDocument() {
      return this.$store.getters["documents/currentDocument"].document;
    },
    requiredDocument() {
      return this.$store.getters["documents/currentDocument"].document
        .required_document;
    },
    redirectBack() {
      return this.$store.getters["documents/redirectBack"];
    },
    breadcrumbs() {
      return [
        {
          text: "Home",
          disabled: false,
          exact: true,
          to: "/"
        },
        {
          text: "Document Vault",
          disabled: false,
          exact: true,
          to: "/document-vault"
        },
        {
          text: i18n.t("routes." + this.redirectBack),
          disabled: false,
          to: this.redirectBack
        },
        {
          text: "Document Overview",
          disabled: true,
          to: this.redirectBack
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;
    let user = await this.$admin.authProvider.checkAuth();
    if (this.$admin.can(["admin", "super_user"])) {
      this.canEdit = true;
      this.canDelete = true;
    } else {
      user.data.permissions.forEach(permission => {
        if (permission.name == "canDelete") {
          this.canEdit = true;
          this.canDelete = true;
        } else if (permission.name == "canEdit") {
          this.canEdit = true;
        }
      });
    }
    this.loading = false;
  },
  methods: {
    async deleteDocument() {
      this.loading = true;
      let docId = this.currentDocument.id;
      await this.$admin.http.delete("/api/documents/" + docId);
      this.dialogOpen = false;
      this.$admin.toast.success("Document successfully deleted");
      this.$router.push({
        path: this.redirectBack
      });
      this.loading = false;
    },
    async archiveDocument() {
      let tempDoc = this.currentDocument;
      tempDoc.is_archived = true;
      await this.$admin.http.put("/api/documents/" + tempDoc.id, tempDoc);
      this.$admin.toast.success("Document successfully archived");
      this.$router.push({
        path: this.redirectBack
      });
    },
    editDocument() {
      this.$router.push({
        path: "/document-vault/edit-document"
      });
    },
    async downloadDocument() {
      let currentFile = this.currentDocument;
      let documentName = null;
      documentName = currentFile.file_path;
      if (documentName) {
        documentName = documentName.split("documents/").pop();
        let headers = new Headers({ responseType: "blob" });
        await this.$admin.http
          .post(
            "/api/stored-documents",
            {
              filename: documentName
            },
            headers
          )
          .then(async response => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = response.data;
            let extension = documentName.split(".").pop();
            documentName = currentFile.name
              .match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
              )
              .join("-")
              .toLowerCase();
            documentName = documentName + "." + extension;
            link.download = documentName;
            link.click();
            URL.revokeObjectURL(link.href);
          });
      }
    }
  }
};
</script>

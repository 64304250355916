<template>
  <div v-if="!loading">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <HelpButton class="mt-4"></HelpButton>
    <div class="mt-5">
      <v-row class="mx-auto justify-content-center">
        <v-col
          cols="4"
          md="4"
          lg="4"
          :key="index"
          v-for="(docCategory, index) in docCategories"
        >
          <div
            class="theme--dark primary-branding-color text-white elevation-6 pa-3 px-5 v-list-item v-list-item--link"
            @click="$router.push({ path: docCategory.url })"
          >
            <p class="text-white mb-0 ml-2">
              {{ docCategory.title }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
export default {
  components: {
    HelpButton
  },
  data() {
    return {
      loading: false,
      company: null,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          exact: true,
          to: "/"
        },
        {
          text: "Document Vault",
          disabled: false,
          exact: true,
          to: "/document-vault"
        },
        {
          text: "AGM & Annual Trustee Reports",
          disabled: true,
          to: "agm-annual-trustee-reports"
        }
      ],
      docCategories: [
        {
          title: "AGM",
          url: "/document-vault/agm"
        },
        {
          title: "Annual Trustee Reports",
          url: "/document-vault/annual-trustee-reports"
        }
      ]
    };
  },
  methods: {}
};
</script>

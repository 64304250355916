<template>
  <div v-if="dataReady">
    <v-row v-if="userRole === 'super_user' || userRole === 'admin'">
      <v-col>
        <form @submit.prevent="updateCompanyProfile" class="mb-5">
          <base-material-card
            icon="mdi-account"
            :title="$t('profile.company-account')"
            color="#2D2D2C"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.company_name')"
                    v-model="company.name"
                    :error-messages="errorMessages.name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.company_number')"
                    v-model="company.company_number"
                    :error-messages="errorMessages.company_number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="assessment_period_end_menu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :label="$t('auth.assessment_period_end')"
                        v-model="company.activePeriod.end_date"
                        required
                        readonly
                        :error-messages="errorMessages.end_date"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="company.activePeriod.end_date"
                      :error-messages="errorMessages.end_date"
                      @input="assessment_period_end_menu = false"
                      :min="tomorrow"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    :label="$t('profile.company_slogan')"
                    v-model="company.company_slogan"
                    :error-messages="errorMessages.company_slogan"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-btn
              color="#2D2D2C"
              :loading="loading"
              class="text-white"
              type="submit"
              >{{ $t("profile.update") }}</v-btn
            >
          </base-material-card>
        </form>
      </v-col>
    </v-row>
    <v-row v-if="userRole === 'super_user' || userRole === 'admin'">
      <v-col>
        <form @submit.prevent="updateBilling" class="mb-5">
          <base-material-card
            icon="mdi-cash"
            :title="$t('profile.billing')"
            color="#2D2D2C"
          >
            <v-card-text>
              <v-row v-if="userRole === 'super_user' || userRole === 'admin'">
                <v-col>
                  <v-text-field
                    :label="$t('profile.company_name')"
                    v-model="company.name"
                    :error-messages="errorMessages.name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.billing_email')"
                    v-model="company.billing_email"
                    :error-messages="errorMessages.billing_email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.vat')"
                    v-model="company.vat_number"
                    :error-messages="errorMessages.vat_number"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.address')"
                    v-model="company.billing_address"
                    :error-messages="errorMessages.billing_address"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-btn
              color="#2D2D2C"
              :loading="loading"
              class="text-white"
              type="submit"
              >{{ $t("profile.update") }}</v-btn
            >
          </base-material-card>
        </form>
      </v-col>
    </v-row>
    <div v-else>
      <h3>Only super users can update company information</h3>
    </div>
  </div>
</template>

<script>
//
import { mapActions } from "vuex";

export default {
  data() {
    return {
      assessment_period_end_menu: false,
      tomorrow: null,
      loading: false,
      userRole: null,
      dataReady: false,
      company: null,
      errorMessages: {}
    };
  },
  async mounted() {
    let user = await this.$admin.authProvider.checkAuth();
    this.userRole = user.data.roles;
    this.user = user.data;
    user.data.email_verified_at != null ? (this.verifiedMail = true) : "";

    if (user.data.company_id) {
      let tempCompany = await this.$store.dispatch("companies/getOne", {
        id: user.data.company_id
      });
      this.company = tempCompany.data;
    }

    let myDate = new Date();

    this.tomorrow =
      myDate.getFullYear() +
      "-" +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (myDate.getDate() + 1)).slice(-2);

    this.dataReady = true;
  },
  methods: {
    ...mapActions({
      checkAuth: "auth/checkAuth"
    }),
    async update(method, url, data) {
      try {
        await this.$admin.http({ method, url, data });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    },
    async updateCompanyProfile() {
      this.loading = true;
      try {
        if (
          await this.update("put", "api/company/info/", {
            id: this.company.id,
            data: this.company
          })
        ) {
          this.$admin.toast.success(this.$t("profile.account_updated"));
        }
      } finally {
        this.loading = false;
      }
    },
    async updateBilling() {
      this.loading = true;
      try {
        if (
          await this.update("put", "api/company/info/", {
            id: this.company.id,
            data: this.company
          })
        ) {
          this.$admin.toast.success(this.$t("profile.company_updated"));
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

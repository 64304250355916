<template>
  <div class="pa-4">
    <h1>
      {{ this.$route.meta.title }}
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments List"
      color="#2D2D2C"
      v-if="!loading"
    >
      <v-text-field
        label="Question title"
        :rules="rules"
        v-model="title"
        hide-details="auto"
      ></v-text-field>
      <v-select
        v-model="selectedPillar"
        :items="pillars"
        item-text="name"
        item-value="id"
        label="Select a pillar"
        v-on:change="changeSelectedPillar"
        :rules="rules"
        persistent-hint
      ></v-select>
      <v-select
        v-model="selectedSection"
        :items="filteredSections"
        item-text="title"
        item-value="id"
        label="Select a section. Available sections change based on the selected pillar"
        v-on:change="changeSelectedSection"
        :rules="selectedSection.pillar_id === 5 ? [] : rules"
        persistent-hint
      ></v-select>
      <v-divider></v-divider>
      <div
        class="row mt-0"
        v-for="(answer, index) in answers"
        v-bind:key="index"
      >
        <div class="col-8">
          <v-text-field
            :label="'Answer ' + (index + 1)"
            :rules="rules"
            v-model="answer.answer"
            hide-details="auto"
          ></v-text-field>
        </div>
        <div class="col-3">
          <v-text-field
            label="Score"
            :rules="rules"
            v-model="answer.score"
            hide-details="auto"
          ></v-text-field>
        </div>
        <div class="col-1" v-if="answers.length > 1">
          <v-btn
            @click="removeAnswer(index)"
            class="mx-2 my-2 theme--dark primary-branding-color"
            fab
            small
          >
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-btn
        @click="addAnswer"
        class="mx-2 my-2 theme--dark primary-branding-color"
        fab
        small
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <div
        class="d-flex"
        v-if="this.$route.name === 'assessment-question-edit'"
      >
        <v-btn
          @click="editQuestion"
          class="ml-auto my-2 theme--dark primary-branding-color"
        >
          Save
        </v-btn>
      </div>
      <div class="d-flex" v-else>
        <v-btn
          @click="createQuestion"
          class="ml-auto my-2 theme--dark primary-branding-color"
        >
          Create
        </v-btn>
      </div>
    </base-material-card>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments List"
      color="#2D2D2C"
      v-if="loading"
    >
      <h2 class="mx-auto mt-2">
        {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
      </h2>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    questionId: String
  },
  data() {
    return {
      rules: [value => !!value || "Required."],
      title: null,
      pillars: null,
      selectedPillar: null,
      selectedSection: null,
      loading: true,
      sections: null,
      errorMessages: {},
      answers: [
        {
          answer: "I don't know",
          score: 0
        },
        {
          answer: "Strongly Disagree",
          score: 1
        },
        {
          answer: "Disagree",
          score: 2
        },
        {
          answer: "Neutral",
          score: 3
        },
        {
          answer: "Agree",
          score: 4
        },
        {
          answer: "Strongly Agree",
          score: 5
        }
      ]
    };
  },
  async mounted() {
    let pillars = await this.$admin.dataProvider.getList("pillars", {});
    this.pillars = pillars.data;
    const sections = await this.$admin.http.get("/api/assessment-sections");
    this.sections = sections.data;
    const tempSection = this.sections.find(section => section.id == this.id);
    this.selectedPillar = tempSection.pillar_id;
    this.changeSelectedSection(tempSection);
    if (this.$route.name === "assessment-question-edit") {
      let question = await this.$admin.http.get(
        "/api/assessment-question/" + this.questionId
      );
      question = question.data;
      this.title = question.title;
      this.selectedPillar = question.pillar_id;
      if (question.sections) {
        this.selectedSection = question.sections[0];
      }
      this.answers = question.answers;
    }
    this.loading = false;
  },
  methods: {
    removeAnswer(index) {
      this.$delete(this.answers, index);
    },
    addAnswer() {
      this.answers.push({
        answer: null,
        score: null
      });
    },
    changeSelectedPillar(pillar) {
      this.selectedPillar = pillar;
    },
    changeSelectedSection(section) {
      this.selectedSection = section;
    },
    validateInput() {
      if (!this.title) {
        this.$admin.toast.error("A question must have a title");
        return;
      }
      if (!this.selectedPillar) {
        this.$admin.toast.error("A question must be related to a pillar");
        return;
      }
      if (this.answers.length < 1) {
        this.$admin.toast.error("A question must have more than one answer");
        return;
      }
      return true;
    },
    async editQuestion() {
      if (this.validateInput()) {
        try {
          await this.$admin.http.post("/api/assessment-question/update", {
            title: this.title,
            pillar: this.selectedPillar,
            answers: this.answers,
            questionId: this.questionId,
            sectionId: this.id
          });
          this.$admin.toast.success("The question has been saved");
          this.$router.push({ path: "/assessments/section/edit/" + this.id });
        } catch (error) {
          this.$admin.toast.error(error.message);
        }
      }
    },
    async createQuestion() {
      if (this.validateInput()) {
        try {
          await this.$admin.http.post("/api/assessment-question/", {
            title: this.title,
            pillar: this.selectedPillar,
            answers: this.answers,
            sectionId: this.id
          });
          this.$admin.toast.success("A question has been created");
          this.$router.push({ path: "/assessments/section/edit/" + this.id });
        } catch (error) {
          this.$admin.toast.error(error.message);
        }
      }
    }
  },
  computed: {
    filteredSections: function() {
      let tempSections = this.sections.filter(section => {
        if (this.selectedPillar && section.pillar_id === this.selectedPillar) {
          return section;
        }
      });
      if (tempSections.length === 1) {
        this.changeSelectedSection(tempSections[0]);
      }
      return tempSections;
    }
  }
};
</script>

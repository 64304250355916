<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="name"></va-field>
              <p class="v-label theme--light">
                {{ $t("resources.companies.fields.users") }}
              </p>
              <v-data-table
                dense
                :headers="headers"
                :items="item.users"
                item-key="email"
                class="elevation-1"
                :items-per-page="5000"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <a class="text-decoration-none" :href="'/users/' + item.id">
                    {{ item.name }}
                  </a>
                </template>

                <template v-slot:item.email="{ item }">
                  <a class="text-decoration-none" :href="'/users/' + item.id">
                    {{ item.email }}
                  </a>
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name"
      },
      { text: "Email", sortable: false, value: "email" }
    ]
  })
};
</script>

<template>
  <div class="pa-4">
    <h1>
      Assessment Edit
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments Sections List"
      color="#2D2D2C"
    >
      <v-data-table
        :items="sections"
        :headers="headers"
        show-expand
        :loading="loading"
        loading-text="Loading... Please wait"
        :single-expand="true"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-btn
            @click="goToSectionAddPage"
            class="ml-auto my-4 theme--dark primary-branding-color"
          >
            New Section
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="goToEditPage(item.id)">
            mdi-pencil
          </v-icon>
          <v-icon @click="deleteSection(item.id)">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ol>
              <li v-bind:key="index" v-for="(answer, index) in item.answers">
                {{ answer.answer }}
              </li>
            </ol>
          </td>
        </template>
        <template v-slot:no-data>
          No sections found in assessment
        </template>
      </v-data-table>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: {
    id: String
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        { text: "Title", value: "title" },
        { text: "Description", value: "description", sortable: true },
        {
          text: "Number of questions",
          value: "questions.length",
          sortable: true
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      sections: [],
      confirmDelete: false,
      loading: true
    };
  },
  async mounted() {
    this.loadSections();
    this.loading = false;
  },
  methods: {
    async loadSections() {
      let assessment = await this.$admin.http.get(
        "/api/assessments/" + this.id
      );
      assessment = assessment.data;
      this.sections = assessment.sections;
    },
    async deleteSection(sectionId) {
      this.loading = true;
      if (this.confirmDelete) {
        try {
          await this.$admin.http.post(
            "/api/assessment-section/delete/" + sectionId
          );
          this.loadSections();
          this.$admin.toast.success(
            "The section has been deleted successfully"
          );
          this.confirmDelete = false;
          this.loading = false;
        } catch (error) {
          this.$admin.toast.error(error.message);
          this.loading = false;
        }
      } else {
        this.confirmDelete = true;
        this.$admin.toast.warning(
          "This would delete the section with all questions in it. Press the delete button again to confirm"
        );
        this.loading = false;
      }
    },
    goToEditPage(sectionId) {
      this.$router.push({
        path: "/assessments/section/edit/" + sectionId
      });
    },
    goToSectionAddPage() {
      this.$router.push({ path: "/assessments/section/create/" + this.id });
    }
  }
};
</script>

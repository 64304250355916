<template>
  <div>
    <div
      :key="selectedSection.id"
      v-for="selectedSection in sections"
      class="pa-4 d-flex flex-column"
    >
      <h1 class="mx-auto">
        {{ selectedSection.title }}
      </h1>
      <p class="mx-auto">{{ selectedSection.description }}</p>
      <v-card outlined class="w-75 mx-auto pa-5">
        <div
          v-bind:key="index"
          v-for="(question, index) in selectedSection.questions"
        >
          <p class="font-weight-bold">
            {{ index + 1 + ". " + question.title }}
          </p>
          <v-radio-group v-model="assessment.response[question.id]">
            <div v-for="answer in question.answers" :key="answer.id">
              <v-radio
                v-if="answer.id === assessment.response[question.id]"
                :label="answer.answer"
                :value="answer.id"
              ></v-radio>
            </div>
          </v-radio-group>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String
  },
  data() {
    return {
      loading: true,
      assessment: null,
      sections: null,
      rules: [value => !!value || "Required."],
      selectedSectionIndex: 0,
      selectedAnswers: {},
      isLastIndex: false
    };
  },
  methods: {
    async getAssessment() {
      let assessmentTemp = await this.$admin.http.get(
        "/api/assessment-responses/" + this.id
      );
      this.assessment = assessmentTemp.data.data;
      this.assessment.response = JSON.parse(this.assessment.response);
    },
    async getSections() {
      const sections = await this.$admin.http.get("/api/assessment-sections");
      this.sections = sections.data;
    }
  },
  async created() {
    let user = (await this.$admin.authProvider.checkAuth()).data;
    let userRole = user.roles;
    let userOwnsResponse = user.company.board.assessment_responses.filter(
      response => response.id == this.id
    );
    if (userRole !== "admin" && userOwnsResponse.length === 0) {
      this.$router.push({ name: "home" });
    }
    await this.getAssessment();
    if (!this.sections) {
      this.getSections();
    }
    this.loading = false;
  }
};
</script>

<style>
.v-label.v-label,
.v-alert.v-alert {
  z-index: 1 !important;
}
</style>

<template>
  <div class="pa-4">
    <h1>
      Assessment Section Edit
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessment Section Questions List"
      color="#2D2D2C"
    >
      <v-data-table
        :items="questions"
        :headers="headers"
        show-expand
        :loading="loading"
        loading-text="Loading... Please wait"
        :single-expand="true"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-btn
            @click="goToQuestionAddPage"
            class="ml-auto my-4 theme--dark primary-branding-color"
          >
            New Question
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="goToEditPage(item.id)">
            mdi-pencil
          </v-icon>
          <v-icon @click="deleteQuestion(item.id)">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ol>
              <li v-bind:key="index" v-for="(answer, index) in item.answers">
                {{ answer.answer }}
              </li>
            </ol>
          </td>
        </template>
        <template v-slot:no-data>
          No questions found in assessment
        </template>
      </v-data-table>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: {
    id: String
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        { text: "Title", value: "title" },
        { text: "Pillar", value: "pillar.name", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ],
      questions: [],
      loading: true
    };
  },
  async mounted() {
    this.loadQuestions();
    this.loading = false;
  },
  methods: {
    async loadQuestions() {
      let assessmentSection = await this.$admin.http.get(
        "/api/assessment-section/" + this.id
      );
      assessmentSection = assessmentSection.data;
      this.questions = assessmentSection.questions;
    },
    async deleteQuestion(questionId) {
      this.loading = true;
      try {
        await this.$admin.http.post(
          "/api/assessment-question/delete/" + questionId
        );
        this.loadQuestions();
        this.$admin.toast.success("The question has been deleted successfully");
        this.loading = false;
      } catch (error) {
        this.$admin.toast.error(error.message);
        this.loading = false;
      }
    },
    goToEditPage(questionId) {
      this.$router.push({
        path: "/assessments/question/edit/" + this.id + "/" + questionId
      });
    },
    goToQuestionAddPage() {
      this.$router.push({ path: "/assessments/question/create/" + this.id });
    }
  }
};
</script>

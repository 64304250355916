import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#008EB4",
        secondary: "#0A2A54",
        accent: "#70D9B8",
        success: "#008EB4",
        warning: "#FFBD59"
      }
    }
  }
});

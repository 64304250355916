<template>
  <div class="pa-4 d-flex flex-column" v-if="!loading && selectedSection">
    <router-link class="px-3 grey--text text--darken-3" :to="{ name: 'home' }">
      &lt; Go to home
    </router-link>
    <h1 class="mx-auto">
      {{ selectedSection.title }}
    </h1>
    <p class="mx-auto">{{ selectedSection.description }}</p>
    <v-card outlined class="w-75 mx-auto pa-5">
      <div>
        <h2 class="font-weight-bold mb-1">
          {{
            selectedQuestionGeneralIndex +
              1 +
              ". " +
              selectedSection.questions[selectedQuestionIndex].title
          }}
        </h2>
        <v-btn
          v-for="(answer, index) in selectedSection.questions[
            selectedQuestionIndex
          ].answers"
          :key="answer.id"
          class="mb-2 text-left h-auto mw-100 d-flex justify-content-start fs-12rem"
          :class="'btn-color-' + colorClasses[index]"
          @click="goToNextQuestion(answer.id)"
          block
          outlined
          elevation="2"
        >
          <pre
            :class="'text-color-' + colorClasses[index]"
            class="font-weight-bold"
            >{{ index + 1 + "." }}</pre
          >
          <pre class="text-black btn-answer ml-2"> {{ answer.answer }}</pre>
        </v-btn>
        <!-- <v-radio
          v-for="answer in selectedSection.questions[selectedQuestionIndex]
            .answers"
          @change="goToNextQuestion(event)"
          :key="answer.id"
          :label="answer.answer"
          :value="answer.id"
        ></v-radio> -->
      </div>
      <div class="d-flex">
        <div class="mr-auto">
          <v-btn
            @click="goToPreviousQuestion"
            v-if="!this.isFirstQuestion"
            class="mr-2 mt-3 theme--dark primary-branding-color"
          >
            Back
          </v-btn>
        </div>
        <div class="ml-auto">
          <!-- <v-btn
            @click="goToNextSection"
            v-if="!isLastSectionIndex"
            class="my-2 theme--dark primary-branding-color"
          >
            Next
          </v-btn> -->
          <!-- <v-btn
            @click="submitAssessment"
            v-if="isLastSectionIndex"
            class="my-2 theme--dark primary-branding-color"
          >
            Submit
          </v-btn> -->
        </div>
      </div>
      <div class="d-flex">
        <p class="mx-auto">
          <small
            >This is question {{ selectedQuestionGeneralIndex + 1 }}/{{
              assessment.questions.length
            }}
          </small>
        </p>
      </div>
    </v-card>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      assessment: null,
      sections: null,
      selectedSection: null,
      rules: [value => !!value || "Required."],
      selectedSectionIndex: 0,
      selectedQuestionIndex: 0,
      selectedQuestionGeneralIndex: 0,
      isLastQuestionInSection: false,
      selectedAnswers: {},
      colorClasses: ["red", "yellow", "pink", "green", "blue"],
      isLastSectionIndex: false,
      isLastQuestionAnswered: false,
      isFirstQuestion: true
    };
  },
  methods: {
    async goToNextQuestion(answerId) {
      this.loading = true;
      this.selectedAnswers[
        this.selectedSection.questions[this.selectedQuestionIndex].id
      ] = answerId;
      this.isFirstQuestion = false;
      if (
        this.selectedSection.questions.length >
        this.selectedQuestionIndex + 1
      ) {
        this.selectedQuestionIndex++;
      } else {
        if (!this.isLastSectionIndex) {
          this.selectedQuestionIndex = 0;
          this.goToNextSection();
        } else {
          this.isLastQuestionAnswered = true;
          await this.submitAssessment();
        }
      }

      if (
        this.isLastSectionIndex &&
        !this.isLastQuestionAnswered &&
        this.selectedSection.questions.length === this.selectedQuestionIndex + 1
      ) {
        this.$admin.toast.warning(
          "This is the last question. Clicking on an answer will submit the assessment"
        );
      }

      if (
        !(
          this.selectedQuestionGeneralIndex >=
          this.assessment.questions.length + 1
        )
      ) {
        this.selectedQuestionGeneralIndex =
          this.selectedQuestionGeneralIndex + 1;
      }

      this.loading = false;
    },
    async goToPreviousQuestion() {
      this.loading = true;
      if (!this.isFirstQuestion && this.selectedQuestionIndex !== 0) {
        this.selectedQuestionIndex = this.selectedQuestionIndex - 1;
      } else if (
        this.selectedQuestionIndex === 0 &&
        this.selectedSectionIndex !== 0
      ) {
        this.selectedQuestionIndex =
          this.sections[this.selectedSectionIndex - 1].questions.length - 1;
        this.goToPreviousSection();
      }
      if (this.selectedQuestionIndex === 0 && this.selectedSectionIndex === 0) {
        this.isFirstQuestion = true;
      }
      this.selectedQuestionGeneralIndex--;
      this.loading = false;
    },
    goToEditPage(id) {
      this.$router.push({ path: "/assessments/edit/" + id });
    },
    async getAssessment() {
      let assessment = await this.$admin.http.get("/api/assessment");
      this.assessment = assessment.data;
      if (this.assessment.sections) {
        this.sections = this.assessment.sections;
        this.selectedSection = this.sections[0];
      }
    },
    async getSections() {
      const sections = await this.$admin.http.get("/api/assessment-sections");
      this.sections = sections.data;
      this.selectedSection = this.sections[0];
    },
    goToHome() {
      this.$router.push({ name: "home" });
    },
    async submitAssessment() {
      this.loading = true;
      if (
        this.assessment.questions.length ===
        Object.keys(this.selectedAnswers).length
      ) {
        try {
          await this.$admin.http.post("/api/assessment", {
            answers: this.selectedAnswers,
            assessmentId: this.assessment.id
          });
          this.$admin.toast.success(
            "Assessment has been successfully submitted"
          );
          this.$router.push({ name: "home" });
        } catch (error) {
          this.$admin.toast.error(error.message);
          this.loading = false;
        }
      } else {
        this.$admin.toast.error(
          "Please answer all questions before submitting"
        );
        this.loading = false;
      }
    },
    goToPreviousSection() {
      this.selectedSectionIndex--;
      if (this.sections[this.selectedSectionIndex]) {
        this.selectedSection = this.sections[this.selectedSectionIndex];
        window.scrollTo(0, 0);
        this.isLastSectionIndex = false;
      }
    },
    goToNextSection() {
      let allQuestionsAnswered = true;

      for (let i = 0; i < this.selectedSection.questions.length; i++) {
        if (
          !Object.prototype.hasOwnProperty.call(
            this.selectedAnswers,
            this.selectedSection.questions[i].id
          )
        ) {
          allQuestionsAnswered = false;
        }
      }
      if (allQuestionsAnswered) {
        this.selectedSectionIndex++;
        if (this.sections[this.selectedSectionIndex]) {
          this.selectedSection = this.sections[this.selectedSectionIndex];
          window.scrollTo(0, 0);
          if (this.sections.length - 1 === this.selectedSectionIndex) {
            this.isLastSectionIndex = true;
          }
        }
      } else {
        this.$admin.toast.error(
          "Please answer all questions in this section before continuing"
        );
      }
    }
  },
  async created() {
    await this.getAssessment();
    if (!this.sections) {
      this.getSections();
    }
    this.loading = false;
  }
};
</script>

<style>
.v-label.v-label,
.v-alert.v-alert {
  z-index: 1 !important;
}
</style>

<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ $t("resources.board-goals.titles.show") }}
              </div>
            </template>
            <v-card-text>
              <va-field source="description"></va-field>
              <va-field source="status"></va-field>
              <va-field
                type="referenceArray"
                source="board_members"
                reference="board-members"
                action="show"
              >
              </va-field>
              <va-field
                type="referenceArray"
                source="strategic_development_goals"
                reference="sustainable-development-goals"
                action="show"
                itemText="description"
              >
              </va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>

import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Terms from "@/views/Terms";
import Verify from "@/views/Verify";
import Profile from "@/views/Profile";
import Error from "@/views/Error";
import i18n from "@/i18n";
import ImpactZone from "@/views/ImpactZone";
import DocumentVault from "@/views/DocumentVault";
import GoverningDocs from "@/views/document-vault/GoverningDocs";
import DocumentOverview from "@/views/document-vault/DocumentOverview";
import OnboardingDocs from "@/views/document-vault/OnboardingDocs";
import AnnualTrusteeReports from "@/views/document-vault/subcategories/AnnualTrusteeReports";
import AGMDocuments from "@/views/document-vault/subcategories/AGMDocuments";
import AGMAnnualTrusteeReports from "@/views/document-vault/AGMAnnualTrusteeReports";
import BoardAndSubCommitteeDocs from "@/views/document-vault/BoardAndSubCommitteeDocs";
import PolicyDocs from "@/views/document-vault/PolicyDocs";
import CreateDocumentWindow from "@/views/CreateDocumentWindow";
import EditDocumentWindow from "@/views/EditDocumentWindow";
import LegalInsuranceRegulationDocs from "@/views/document-vault/LegalInsuranceRegulationDocs";
import LegalDocs from "@/views/document-vault/subcategories/LegalDocs";
import RegulationDocs from "@/views/document-vault/subcategories/RegulationDocs";
import InsuranceDocs from "@/views/document-vault/subcategories/InsuranceDocs";
import Home from "@/views/Home";
import InviteContributors from "@/views/InviteContributors";
import Help from "@/views/Help";
import CompanyProfile from "@/views/CompanyProfile";
import ProfilesDashboard from "@/views/ProfilesDashboard";
import AssessmentsList from "@/views/assessment/admin/AssessmentsList";
import SubmittedAssessmentsList from "@/views/assessment/admin/SubmittedAssessmentsList";
import AssessmentsEdit from "@/views/assessment/admin/AssessmentsEdit";
import AddAssessmentQuestion from "@/views/assessment/admin/AddAssessmentQuestion";
import AddAssessmentSection from "@/views/assessment/admin/AddAssessmentSection";
import Assessment from "@/views/assessment/user/Assessment";
import AssessmentSectionEdit from "@/views/assessment/admin/AssessmentSectionEdit";
import ViewAssessment from "@/views/assessment/user/ViewAssessment";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "/",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home")
  },
  children: [
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        title: i18n.t("routes.home")
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard")
      }
    },
    {
      path: "/impact-zone",
      name: "impact-zone",
      component: ImpactZone,
      meta: {
        title: i18n.t("routes.impact-zone")
      }
    },
    {
      path: "/document-vault",
      name: "document-vault",
      component: DocumentVault,
      meta: {
        title: i18n.t("routes.document-vault")
      }
    },
    {
      path: "/document-vault/document-overview",
      name: "document-overview",
      component: DocumentOverview,
      meta: {
        title: i18n.t("routes.document-vault")
      }
    },
    {
      path: "/document-vault/governing-docs",
      name: "governing-docs",
      component: GoverningDocs,
      meta: {
        title: i18n.t("routes.governing-docs")
      }
    },
    {
      path: "/document-vault/onboarding-docs",
      name: "onboarding-docs",
      component: OnboardingDocs,
      meta: {
        title: i18n.t("routes.onboarding-docs")
      }
    },
    {
      path: "/document-vault/board-subcommittee-docs",
      name: "board-subcommittee-docs",
      component: BoardAndSubCommitteeDocs,
      meta: {
        title: i18n.t("routes.board-subcommittee-docs")
      }
    },
    {
      path: "/document-vault/annual-trustee-reports",
      name: "annual-trustee-reports",
      component: AnnualTrusteeReports,
      meta: {
        title: i18n.t("routes.annual-trustee-reports")
      }
    },
    {
      path: "/document-vault/agm",
      name: "agm-documents",
      component: AGMDocuments,
      meta: {
        title: i18n.t("routes.agm")
      }
    },
    {
      path: "/document-vault/agm-annual-trustee-reports",
      name: "agm-annual-trustee-reports",
      component: AGMAnnualTrusteeReports,
      meta: {
        title: i18n.t("routes.agm-annual-trustee-reports")
      }
    },
    {
      path: "/document-vault/legal-insurance-regulation-docs",
      name: "legal-insurance-regulation-docs",
      component: LegalInsuranceRegulationDocs,
      meta: {
        title: i18n.t("routes.legal-insurance-regulation-docs")
      }
    },
    {
      path: "/document-vault/policy-docs",
      name: "policy-docs",
      component: PolicyDocs,
      meta: {
        title: i18n.t("routes.policy-docs")
      }
    },
    {
      path: "/document-vault/insurance-docs",
      name: "insurance-docs",
      component: InsuranceDocs,
      meta: {
        title: i18n.t("routes.insurance-docs")
      }
    },
    {
      path: "/document-vault/regulation-docs",
      name: "regulation-docs",
      component: RegulationDocs,
      meta: {
        title: i18n.t("routes.regulation-docs")
      }
    },
    {
      path: "/document-vault/legal-docs",
      name: "legal-docs",
      component: LegalDocs,
      meta: {
        title: i18n.t("routes.legal-docs")
      }
    },
    {
      path: "/document-vault/create-document",
      name: "create-document",
      component: CreateDocumentWindow,
      meta: {
        title: i18n.t("routes.create-doc")
      }
    },
    {
      path: "/document-vault/edit-document",
      name: "edit-document",
      component: EditDocumentWindow,
      meta: {
        title: i18n.t("routes.edit-doc")
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        title: i18n.t("routes.profile")
      }
    },
    {
      path: "/profiles-dashboard",
      name: "profiles-dashboard",
      component: ProfilesDashboard,
      meta: {
        title: i18n.t("routes.profiles-dashboard")
      }
    },
    {
      path: "/company-profile",
      name: "company-profile",
      component: CompanyProfile,
      meta: {
        title: i18n.t("routes.company-profile")
      }
    },
    {
      path: "/invite-contributors",
      name: "invite-contributors",
      component: InviteContributors,
      meta: {
        title: i18n.t("routes.invite-contributors")
      }
    },
    {
      path: "/verify",
      name: "verify",
      component: Verify,
      meta: {
        title: "Verify Email"
      }
    },
    {
      path: "/help",
      name: "help",
      component: Help,
      meta: {
        title: "Help Section"
      }
    },
    {
      path: "/assessment",
      name: "assessment",
      component: Assessment,
      meta: {
        title: "Assessment"
      }
    },
    {
      path: "/assessments",
      name: "assessments",
      component: AssessmentsList,
      meta: {
        title: "Assessments List"
      }
    },
    {
      path: "/submitted-assessments",
      name: "submitted-assessments",
      component: SubmittedAssessmentsList,
      meta: {
        title: "Submitted Assessments List"
      }
    },
    {
      path: "/submitted-assessments/view/:id",
      props: true,
      name: "view-assessment",
      component: ViewAssessment,
      meta: {
        title: "View Assessment"
      }
    },
    {
      path: "/assessments/edit/:id",
      props: true,
      name: "assessments-edit",
      component: AssessmentsEdit,
      meta: {
        title: "Assessment Edit"
      }
    },
    {
      path: "/assessments/question/create/:id",
      props: true,
      name: "assessment-question-add",
      component: AddAssessmentQuestion,
      meta: {
        title: "Add Assessment Question"
      }
    },
    {
      path: "/assessments/section/create/:id",
      props: true,
      name: "assessment-section-add",
      component: AddAssessmentSection,
      meta: {
        title: "Add Assessment Section"
      }
    },
    {
      path: "/assessments/question/edit/:id/:questionId",
      props: true,
      name: "assessment-question-edit",
      component: AddAssessmentQuestion,
      meta: {
        title: "Edit Assessment Question"
      }
    },
    {
      path: "/assessments/section/edit/:id",
      props: true,
      name: "assessment-section-edit",
      component: AssessmentSectionEdit,
      meta: {
        title: "Edit Assessment Section"
      }
    },
    {
      path: "/terms-and-conditions",
      name: "Terms & Conditions",
      component: Terms,
      meta: {
        title: "Terms & Conditions"
      }
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found")
      }
    }
  ]
};

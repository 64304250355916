<template>
  <div v-if="!loading && company">
    <HelpButton></HelpButton>
    <div class="mt-5">
      <div class="d-flex mt-5">
        <v-row class="mx-auto">
          <v-col class="mx-auto" v-if="!selectedPillar" cols="12">
            <h1 class="mx-auto">{{ $t("impact-zone.select-pillar") }}</h1>
          </v-col>
          <v-col
            class="mx-auto"
            v-if="selectedPillar && !selectedCounty"
            cols="12"
          >
            <h1 class="mx-auto">{{ $t("impact-zone.select-county") }}</h1>
          </v-col>
          <v-col
            @click="choosePillar"
            cols="6"
            md="3"
            lg="3"
            :key="pillar.id"
            v-for="pillar in pillars"
            v-bind:value="pillar.name"
          >
            <div
              v-bind:value="pillar.name"
              class="theme--dark primary-branding-color text-white elevation-6 pa-3 px-5 v-list-item v-list-item--link"
              v-bind:class="[
                selectedPillar === pillar ? 'v-list-item--active' : ''
              ]"
            >
              <i
                class="mdi"
                v-bind:class="$t('resources.pillars.' + pillar.name + '-icon')"
                v-bind:value="pillar.name"
              >
              </i>
              <p class="text-white mb-0 ml-2" v-bind:value="pillar.name">
                {{ $t("resources.pillars." + pillar.name) }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <l-map
      v-if="selectedPillar && selectedPillar.name !== 'governance'"
      :zoom="zoom"
      :center="center"
      class="mt-4"
      style="height: 500px; width: 100%; z-index: 1"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-geo-json
        v-if="show"
        :geojson="geojson"
        :options="options"
        :options-style="styleFunction"
        @click="clickedJson"
      />
      <!--      <l-marker :lat-lng="marker" />-->
    </l-map>
    <div v-if="selectedCounty && selectedPillar.name !== 'governance'">
      <v-row>
        <v-col cols="9">
          <h1 class="my-3">{{ selectedCounty.LGDNAME }}</h1>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedSector"
            :items="sectors"
            label="Select sector"
            v-on:change="changeSelectedSector"
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <div :key="data.count" v-for="data in selectedPillar.dataPoints">
        <div
          class="my-2"
          v-if="
            data.county_name === selectedCounty.LGDNAME &&
              (selectedSector === 'General' ||
                data.datapoint === 'Population:' ||
                data.sector.indexOf(selectedSector) !== -1)
          "
        >
          <h3 class="mb-0 font-weight-normal d-flex">
            {{ data.datapoint }}
            {{ data.value }}
            <a
              v-if="data.source"
              class="d-flex mb-auto no-underline"
              target="_blank"
              :href="data.source"
            >
              <v-icon x-small class="mb-auto" color="#2D2D2C">
                mdi-download-circle
              </v-icon>
            </a>
          </h3>
          <div v-if="data.datapoint !== 'Population:'">
            <v-chip
              small
              class="mb-2 mr-1 chip-sdg"
              :key="sdg.id"
              v-for="sdg in data.sdgs"
              :color="sdg.color ? sdg.color : 'primary'"
            >
              <a
                class="text-white"
                target="_blank"
                :key="sdg.id"
                :href="sdg.goal_url"
              >
                {{ sdg.id }}
              </a>
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        selectedPillar &&
          selectedPillar.name !== 'governance' &&
          !selectedCounty
      "
    >
      <v-row>
        <v-col cols="9">
          <h1 class="my-3">Northern Ireland</h1>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedSector"
            :items="sectors"
            label="Select sector"
            v-on:change="changeSelectedSector"
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <div :key="data.count" v-for="data in selectedPillar.dataPoints">
        <div
          class="my-2"
          v-if="
            data.county_name == 'Northern Ireland' &&
              (selectedSector === 'General' ||
                data.datapoint === 'Population:' ||
                data.sector.indexOf(selectedSector) !== -1)
          "
        >
          <h3 class="mb-0 font-weight-normal d-flex">
            {{ data.datapoint }}
            {{ data.value }}
            <a
              v-if="data.source"
              class="d-flex mb-0 ml-1 no-underline"
              target="_blank"
              :href="data.source"
            >
              <v-icon class="opacity-07" color="#2D2D2C">
                mdi-download-circle
              </v-icon>
            </a>
          </h3>
          <div v-if="data.datapoint !== 'Population:'">
            <v-chip
              small
              class="mb-2 mr-1"
              :key="sdg.id"
              v-for="sdg in data.sdgs"
              :color="sdg.color ? sdg.color : 'primary'"
            >
              <a
                class="text-white"
                target="_blank"
                :key="sdg.id"
                :href="sdg.goal_url"
              >
                {{ sdg.id }}
              </a>
            </v-chip>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedPillar">
      <div class="ml-3" v-if="selectedPillar.name === 'governance'">
        <h1 class="my-3">{{ $t("impact-zone.summary") }}</h1>
        <div class="ml-1" :key="data.count" v-for="data in governanceData">
          <div class="my-2">
            <h3 class="mb-0 font-weight-normal">
              {{ data.datapoint }}
              <b>{{ data.value }}</b>
            </h3>
          </div>
        </div>

        <!-- Key submission dates -->
        <div v-if="company.company_number">
          <div class="d-flex mb-2" v-if="company.last_accounts_date">
            <div class="mr-2 d-flex">
              <img
                width="25"
                height="25"
                src="../assets/checkmark-green.png"
                class="mr-2 display-2 color-green my-auto ml-1"
              />
            </div>
            <h3 class="mr-2 my-auto font-weight-normal">
              {{ $t("dashboard.last-accounts") }}
            </h3>
            <h3 class="my-auto">
              <b>{{ company.last_accounts_date }}</b>
            </h3>
          </div>
          <div class="d-flex" v-if="company.next_accounts_date">
            <div class="mr-2 d-flex">
              <img
                width="25"
                height="25"
                src="../assets/overdue.png"
                class="my-auto ml-1 mr-2"
                :key="company.next_accounts_overdue"
                v-if="company.next_accounts_overdue"
              />
              <img
                v-else
                width="25"
                height="25"
                src="../assets/due.png"
                class="ml-1 my-auto mr-2"
              />
            </div>
            <h3 class="mr-2 my-auto font-weight-normal">
              {{ $t("dashboard.next-accounts") }}
            </h3>
            <h3 class="my-auto">
              <b>{{ company.next_accounts_date }}</b>
            </h3>
          </div>
        </div>
        <div class="pa-6" v-else>
          <v-alert
            prominent
            color="#F2A900"
            outlined
            icon="mdi-information-outline"
          >
            <v-row align="center">
              <v-col class="grow text-black">
                {{ $t("dashboard.company_number_message") }}
              </v-col>
              <v-col class="shrink">
                <v-btn href="/profile"> {{ $t("dashboard.click_here") }}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
import { LGeoJson, LMap, LTileLayer } from "vue2-leaflet";
import cachedGeoJson from "../assets/admin-districts.json";

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    HelpButton
  },
  data() {
    return {
      selectedPillar: null,
      loading: false,
      show: true,
      selectedCounty: null,
      company: null,
      enableTooltip: true,
      governanceData: [],
      selectedSector: "General",
      sectors: null,
      zoom: 8,
      center: [54.6373, -6.6522],
      geojson: cachedGeoJson,
      pillars: null,
      fillColor: "#e4ce7f",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    };
  },
  async mounted() {
    this.loading = true;
    let user = await this.$admin.authProvider.checkAuth();
    if (user.data.company_id) {
      let tempCompany = await this.$store.dispatch("companies/getOne", {
        id: user.data.company_id
      });
      this.company = tempCompany.data;
    }
    let pillars = await this.$admin.dataProvider.getList("pillars", {});
    pillars.data.splice(pillars.data.length - 1, 1);
    this.pillars = pillars.data;
    let tempDataPoints = await this.$admin.http.get("/api/data-points");
    await this.getGovernanceData();
    let dataPoints = tempDataPoints.data;
    let tempSectors = [];
    tempSectors.push("General");
    await this.pillars.forEach(pillar => {
      pillar.dataPoints = [];
      if (pillar.name !== "governance") {
        dataPoints.forEach(dataPoint => {
          if (dataPoint.sector !== null && !Array.isArray(dataPoint.sector)) {
            dataPoint.sector = dataPoint.sector.split(";");
            for (let i = 0; i < dataPoint.sector.length; i++) {
              if (
                dataPoint.sector[i] &&
                tempSectors.indexOf(dataPoint.sector[i]) === -1
              ) {
                tempSectors.push(dataPoint.sector[i]);
              }
            }
          }
          dataPoint.sdgs.forEach(sdg => {
            if (sdg.pillar_id === pillar.id) {
              if (pillar.dataPoints.indexOf(dataPoint) === -1) {
                pillar.dataPoints.push(dataPoint);
              }
            }
          });
        });
      }
    });
    this.sectors = tempSectors;
    this.loading = false;
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        return {
          weight: 2,
          color: "#ECEFF1",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 0.7
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip("<div>" + feature.properties.LGDNAME + "</div>", {
          permanent: false,
          sticky: true
        });
      };
    }
  },
  methods: {
    changeSelectedSector(sector) {
      this.selectedSector = sector;
    },
    async getGovernanceData() {
      let governanceData = await this.$admin.http.get(
        "api/get-infographic-data"
      );

      let count = {
        value: governanceData.data.count,
        datapoint: "The number of officers in your company is:"
      };
      this.governanceData.push(count);
      if (governanceData.data.highestAge) {
        let highestAgeDp = {
          value: governanceData.data.highestAge,
          datapoint: "The oldest officer in your company is"
        };
        let lowestAgeDp = {
          value: governanceData.data.lowestAge,
          datapoint: "The youngest officer in your company is"
        };
        let averageAgeDp = {
          value: governanceData.data.averageAge,
          datapoint: "The average age in your company is"
        };
        this.governanceData.push(lowestAgeDp, highestAgeDp, averageAgeDp);
      }
      if (governanceData.data.nationality) {
        let nationalities = {
          value: governanceData.data.nationality[0].nationality,
          datapoint:
            governanceData.data.nationality[0].percentage +
            " of the officers in your company are:"
        };
        this.governanceData.push(nationalities);
      }
      if (governanceData.data.gender) {
        let genders = {
          value: governanceData.data.gender[0].gender,
          datapoint:
            governanceData.data.gender[0].percentage +
            " of the officers in your company are:"
        };
        this.governanceData.push(genders);
      }
    },
    async choosePillar(event) {
      var pillarName = event["target"]["attributes"][0]["value"];
      var tempSelectedPillar = null;
      this.pillars.filter(function(pillar) {
        if (pillar.name == pillarName.replace(/\s+/g, "-").toLowerCase()) {
          tempSelectedPillar = pillar;
        }
      });
      if (tempSelectedPillar) {
        this.selectedPillar = tempSelectedPillar;
        //this.selectedSector = "General";
      }
    },
    clickedJson(event) {
      if (
        this.selectedCounty !== event["sourceTarget"]["feature"]["properties"]
      ) {
        this.selectedCounty = event["sourceTarget"]["feature"]["properties"];
      } else {
        this.selectedCounty = null;
      }
    }
  },
  async created() {
    this.loading = true;
    // const response = await fetch(
    //   // "@assets/districts.geojson"
    //   "https://osni-spatialni.opendata.arcgis.com/datasets/eaa08860c50045deb8c4fdc7fa3dac87_2.geojson?outSR=%7B%22latestWkid%22%3A29902%2C%22wkid%22%3A29900%7D"
    //   //"https://osni-spatialni.opendata.arcgis.com/datasets/c43e13180e6f4587a59ee0de5ac3b70c_1.geojson?outSR=%7B%22latestWkid%22%3A29902%2C%22wkid%22%3A29900%7D"
    // );
    // //const response = require("@/assets/districts.geojson");
    // this.geojson = await response.json();
    this.loading = false;
  }
};
</script>

<template>
  <div v-if="!loading">
    <HelpButton></HelpButton>
    <div class="mt-5 w-50 mx-auto">
      <base-material-card icon="mdi-file-document-alert" color="#2D2D2C">
        <template v-slot:heading>
          <div class="display-2">
            <div class="display-2">
              {{ $t("resources.documents.titles.edit") }}
            </div>
          </div>
        </template>
        <v-card-text v-if="currentDocument">
          <v-select
            v-model="currentDocument.categories.category"
            :items="categories"
            item-text="name"
            item-value="id"
            :rules="catRules"
            @change="changeCategory()"
            label="Select a category"
            return-object
            single-line
          ></v-select>
          <v-select
            v-if="
              currentDocument.categories.category &&
                currentDocument.categories.category.sub_categories &&
                currentDocument.categories.category.sub_categories.length > 0
            "
            v-model="currentDocument.categories.subCategory"
            :items="currentDocument.categories.category.sub_categories"
            item-text="name"
            item-value="id"
            :rules="subCatRules"
            label="Select a sub-category"
            return-object
            single-line
          ></v-select>
          <v-select
            v-if="currentDocument.categories.subCategory"
            v-model="currentDocument.required_document"
            :items="currentDocument.categories.subCategory.required_documents"
            item-text="name"
            item-value="id"
            :rules="requiredDocRules"
            label="Select a document you'd like to upload"
            return-object
            single-line
          ></v-select>
          <v-select
            v-else-if="currentDocument.categories.category"
            v-model="currentDocument.required_document"
            :items="currentDocument.categories.category.required_documents"
            item-text="name"
            item-value="id"
            :rules="requiredDocRules"
            label="Select a document you'd like to upload"
            return-object
            single-line
          ></v-select>
          <p
            v-if="
              currentDocument.required_document &&
                currentDocument.required_document.is_submitted
            "
          >
            <small>
              This document has already been submitted. Submitting it again
              would archive the old version.
            </small>
          </p>
          <v-text-field
            :rules="nameRules"
            label="Name"
            v-model="currentDocument.name"
            :error-messages="errorMessages.name"
            required
          ></v-text-field>
          <v-textarea
            label="Description"
            :rules="descriptionRules"
            v-model="currentDocument.description"
            required
          ></v-textarea>
          <v-text-field
            label="Url"
            v-model="currentDocument.url"
            required
          ></v-text-field>
          <div>
            <v-file-input
              label="Upload a file if you want to replace the one uploaded"
              v-model="currentDocument.file"
            ></v-file-input>
          </div>
        </v-card-text>
        <v-btn
          @click="saveDocument"
          variant="outlined"
          size="x-large"
          icon
          color="#2D2D2C"
        >
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
      </base-material-card>
    </div>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
export default {
  props: ["id", "title", "item"],
  components: {
    HelpButton
  },
  data() {
    return {
      loading: false,
      company: null,
      categories: [],
      subCategories: [],
      errorMessages: {},
      selectedCategory: null,
      selectedSubCategory: null,
      catRules: [v => !!v || "Category is required"],
      subCatRules: [v => !!v || "Sub-Category is required"],
      requiredDocRules: [v => !!v || "A selected document is required"],
      nameRules: [
        v => !!v || "Title is required",
        v => (v && v.length <= 250) || "Title must be less than 80 characters"
      ],
      urlRules: [v => !!v || "Url is required if no file is uploaded"],
      descriptionRules: [
        v => !!v || "Description is required",
        v =>
          (v && v.length <= 250) ||
          "Description must be less than 250 characters"
      ],
      fileRules: [
        v => !!v || "File is required when no url is specified",
        v =>
          (v && v.type == "application/msword") ||
          (v && v.type == "application/pdf") ||
          "Only doc/docx and pdf documents are allowed"
      ]
    };
  },
  methods: {
    async saveDocument() {
      let formData = new FormData();
      formData.append("id", this.currentDocument.id);
      if (this.currentDocument.file) {
        formData.append("file", this.currentDocument.file);
      }
      if (this.currentDocument.url) {
        formData.append("url", this.currentDocument.url);
      }
      formData.append("name", this.currentDocument.name);
      formData.append("_method", "put");
      formData.append("description", this.currentDocument.description);
      if (this.currentDocument.categories.subCategory) {
        formData.append(
          "category_id",
          this.currentDocument.categories.subCategory.id
        );
      } else {
        formData.append(
          "category_id",
          this.currentDocument.categories.category.id
        );
      }
      if (
        this.currentDocument.requiredDoc &&
        this.currentDocument.requiredDoc.name !== "Other"
      ) {
        formData.append(
          "required_document_id",
          this.currentDocument.requiredDoc.id
        );
      }
      await this.$admin.http
        .post("/api/documents/" + this.currentDocument.id, formData)
        .then(() => {
          this.$admin.toast.success("Document successfully created");
          this.$store.dispatch("documents/clearDocument");
          this.$router.push({
            path: this.redirectBack
          });
        })
        .catch(error => {
          this.$admin.toast.error(
            error.errors[Object.keys(error.errors)[0]][0]
          );
        });
    },
    changeCategory() {
      this.currentDocument.categories.subCategory = null;
    },
    addOtherRequiredDocument(category) {
      category.required_documents.push({
        name: "Other",
        id: null
      });
      return category;
    },
    findSelectedCategoryAndSubCategories() {
      this.currentDocument.categories = {};
      this.categories = this.categories.filter(category => {
        category = this.addOtherRequiredDocument(category);
        if (category.sub_categories?.length > 0) {
          category.sub_categories?.filter(subCategory => {
            subCategory = this.addOtherRequiredDocument(subCategory);
            if (subCategory.id === this.currentDocument.category_id) {
              this.currentDocument.categories.subCategory = subCategory;
            }
          });
        }
        if (category.id === this.currentDocument.category_id) {
          this.currentDocument.categories.category = category;
        }
        if (category.sub_categories?.length > 0) {
          category.sub_categories.filter(subCategory => {
            if (subCategory.required_documents) {
              subCategory = this.addOtherRequiredDocument(subCategory);
              return subCategory;
            }
          });
        } else {
          category = this.addOtherRequiredDocument(category);
        }
        // console.log(category);
        return category;
      });
    }
  },
  computed: {
    currentDocument() {
      return this.$store.getters["documents/currentDocument"].document;
    },
    redirectBack() {
      return this.$store.getters["documents/redirectBack"];
    }
  },
  async mounted() {
    this.loading = true;
    let tempCategories = await this.$admin.http.get("/api/document-categories");
    this.categories = tempCategories.data;
    if (this.currentDocument) {
      await this.findSelectedCategoryAndSubCategories();
    }
    this.loading = false;
  }
};
</script>

<template>
  <div class="pa-4">
    <h1>
      {{ this.$route.meta.title }}
    </h1>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments List"
      color="#2D2D2C"
      v-if="!loading"
    >
      <v-text-field
        label="Section title"
        :rules="rules"
        v-model="title"
        hide-details="auto"
      ></v-text-field>
      <v-text-field
        label="Section description"
        :rules="rules"
        v-model="description"
        hide-details="auto"
      ></v-text-field>
      <v-select
        v-model="selectedPillar"
        :items="pillars"
        item-text="name"
        item-value="id"
        label="Select a pillar"
        v-on:change="changeSelectedPillar"
        :rules="rules"
        persistent-hint
      ></v-select>
      <div class="d-flex">
        <v-btn
          @click="createSection"
          class="ml-auto my-2 theme--dark primary-branding-color"
        >
          Create
        </v-btn>
      </div>
    </base-material-card>
    <base-material-card
      icon="mdi-file-document-alert"
      title="Assessments List"
      color="#2D2D2C"
      v-if="loading"
    >
      <h2 class="mx-auto mt-2">
        {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
      </h2>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    questionId: String
  },
  data() {
    return {
      rules: [value => !!value || "Required."],
      title: null,
      pillars: null,
      selectedPillar: null,
      loading: true,
      description: null,
      errorMessages: {}
    };
  },
  async mounted() {
    let pillars = await this.$admin.dataProvider.getList("pillars", {});
    this.pillars = pillars.data;
    this.loading = false;
  },
  methods: {
    changeSelectedPillar(pillar) {
      this.selectedPillar = pillar;
    },
    validateInput() {
      if (!this.title) {
        this.$admin.toast.error("A section must have a title");
        return;
      }
      if (!this.selectedPillar) {
        this.$admin.toast.error("A section must be related to a pillar");
        return;
      }
      if (!this.description) {
        this.$admin.toast.error("A section must have a descripion");
        return;
      }
      return true;
    },
    async createSection() {
      if (this.validateInput()) {
        try {
          await this.$admin.http.post("/api/assessment-section/", {
            title: this.title,
            pillar: this.selectedPillar,
            description: this.description,
            assessmentId: this.id
          });
          this.$admin.toast.success("A section has been created");
          this.$router.push({ path: "/assessments/edit/" + this.id });
        } catch (error) {
          this.$admin.toast.error(error.message);
        }
      }
    }
  }
};
</script>

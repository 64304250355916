import i18n from "@/i18n";
import resources from "@/resources";
import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import axios from "axios";
import trimEnd from "lodash/trimEnd";
import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
import "vuetify-admin/src/loader";
import { fr } from "vuetify-admin/src/locales";
import { en } from "@/locales";
import {
  laravelDataProvider,
  sanctumAuthProvider
} from "vuetify-admin/src/providers";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" }
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Boards123",
  routes,
  locales: {
    en,
    fr
  },
  translations: ["en", "fr"],
  dataProvider: laravelDataProvider(http),
  authProvider: sanctumAuthProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
    tinyMCE: {
      language: navigator.language.replace("-", "_"),
      imageUploadUrl: "/api/upload",
      fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`
    },
    list: {
      itemsPerPage: 50,
      itemsPerPageOptions: [50, 100, 200, 500]
    }
  }
});

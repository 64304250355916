<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ item.description }}
              </div>
            </template>
            <v-card-text>
              <va-field source="description"></va-field>
              <v-row justify="center">
                <v-col sm="6">
                  <va-field source="pillar.name"></va-field>
                  <va-field source="goal_url" type="url"></va-field>
                </v-col>
                <v-col sm="6">
                  <va-field source="goal_icon" type="image"></va-field>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>

<template>
  <v-form ref="form">
    <va-messages></va-messages>
    <v-alert v-if="message" type="error">{{ message }}</v-alert>

    <div class="text-left">
      <router-link class="text-body-4" :to="{ name: 'register' }">
        &lt; {{ $t("auth.go_back") }}
      </router-link>
    </div>

    <v-text-field
      :label="$t('auth.first_name')"
      prepend-icon="mdi-account"
      v-model="form.first_name"
      :rules="nameRules"
      required
      :error-messages="errorMessages.first_name"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.last_name')"
      prepend-icon="mdi-account"
      v-model="form.last_name"
      :rules="nameRules"
      required
      :error-messages="errorMessages.last_name"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.company_role')"
      prepend-icon="mdi-badge-account-outline"
      v-model="form.company_role"
      :rules="roleRules"
      required
      :error-messages="errorMessages.company_role"
    ></v-text-field>

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        @click="saveForm"
        text
        rounded
      >
        {{ $t("auth.next") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    token: String
  },
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        company_role: null
      },
      roleRules: [
        value => value !== null || "Company role is required",
        value => {
          if (value) {
            return (
              value.length <= 40 ||
              "Company role must be less than 40 characters"
            );
          }
          return true;
        }
      ],
      nameRules: [
        value => value !== null || "Name is required",
        value => {
          if (value) {
            return value.length <= 80 || "Name must be less than 80 characters";
          }
          return true;
        }
      ],
      errorMessages: {},
      loading: false,
      message: null
    };
  },
  computed: {
    currentForm() {
      return this.$store.getters["forms/currentForm"];
    }
  },
  async mounted() {
    if (this.currentForm) {
      this.form = this.currentForm;
    }
  },
  methods: {
    async saveForm() {
      this.loading = true;
      try {
        await this.$admin.http.post("api/registration/validate", this.form);
        this.$store.dispatch("forms/saveFormData", this.form);
        this.$router.push({ name: "register_step_3" });
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
        }
        this.loading = false;
      }
      this.loading = false;
    }
  },
  components: {}
};
</script>

<template>
  <div v-if="dataReady">
    <v-row>
      <v-col>
        <form @submit.prevent="inviteContributors">
          <base-material-card
            color="#2D2D2C"
            icon="mdi-account-multiple-plus"
            :title="$t('profile.contributor')"
          >
            <v-card-text
              v-for="(contributorForm, index) in contributorForms"
              :key="index"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('profile.contributor_first_name')"
                    v-model="contributorForm.contributor_first_name"
                    required
                    :error-messages="errorMessages.contributor_first_name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.contributor_last_name')"
                    v-model="contributorForm.contributor_last_name"
                    required
                    :error-messages="errorMessages.contributor_last_name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('profile.contributor_email')"
                    type="email"
                    v-model="contributorForm.contributor_email"
                    required
                    :error-messages="errorMessages.contributor_email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="mb-4 mx-2">
              <v-btn
                @click="addContributor"
                class="mx-2"
                small
                fab
                color="#F2A900"
              >
                <v-icon class="text-white">
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
            <v-btn
              :loading="contributorInviting"
              color="#2D2D2C"
              type="submit"
              class="text-white"
              >{{ $t("profile.contributor_invite") }}</v-btn
            >
          </base-material-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      contributorInviting: false,
      dataReady: false,
      contributorForms: [
        {
          contributor_first_name: null,
          contributor_last_name: null,
          contributor_email: null
        }
      ],
      passwordForm: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      errorMessages: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  async mounted() {
    let user = await this.$admin.authProvider.checkAuth();
    this.userRole = user.data.roles;
    this.dataReady = true;
  },
  methods: {
    ...mapActions({
      checkAuth: "auth/checkAuth"
    }),
    addContributor() {
      if (this.contributorForms.length < 10) {
        this.contributorForms.push({
          contributor_first_name: null,
          contributor_last_name: null,
          contributor_email: null
        });
      } else {
        this.$admin.toast.error(
          "You can only add up to 10 contributors at a time"
        );
      }
    },
    async update(method, url, data) {
      try {
        await this.$admin.http({ method, url, data });

        this.errorMessages = {};
        return true;
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
      }
      return false;
    },
    async inviteContributors() {
      this.contributorInviting = true;

      try {
        if (
          await this.update(
            "post",
            "/api/invite-contributors",
            this.contributorForms
          )
        ) {
          /**
           * Reset the form
           */
          this.contributorForms = [
            {
              contributor_first_name: null,
              contributor_last_name: null,
              contributor_email: null
            }
          ];
          this.$admin.toast.success(this.$t("profile.contributor_invited"));
        }
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
        }
      } finally {
        this.contributorInviting = false;
      }
    }
  }
};
</script>

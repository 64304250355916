<template>
  <base-material-card
    icon="mdi-file-document-alert"
    :title="$t('routes.' + route)"
    color="#2D2D2C"
  >
    <ArchiveSwitch></ArchiveSwitch>
    <v-data-table
      :items="docs"
      :headers="isArchive ? archiveHeaders : headers"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-btn
          v-if="canEdit"
          @click="createDocument"
          class="ml-auto my-4 theme--dark primary-branding-color"
        >
          New Document
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <span
          class="cursor-pointer"
          v-if="item.is_submitted || item.isOther || isArchive"
          @click="goToOverview(item)"
          >{{ item.name }}</span
        >
        <span
          class="cursor-pointer"
          v-else-if="canEdit"
          @click="createDocument(item)"
          >{{ item.name }}</span
        >
        <span v-else>{{ item.name }}</span>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:item.is_submitted="{ item }">
        <v-icon v-if="item.is_submitted && !isArchive" aria-hidden="false">
          mdi-check
        </v-icon>
        <v-icon v-else-if="!item.isOther && !isArchive" aria-hidden="false">
          mdi-close
        </v-icon>
      </template>
      <!-- <template v-slot:item.active="props">
            <v-edit-dialog
              :return-value.sync="props.item.active"
              @save="save(props.item)"
              @cancel="cancel"
              large
            >
              {{ props.item.active }}
              <template v-slot:input>
                <v-switch
                  v-model="props.item.active"
                  :label="props.item.active ? 'Active' : 'Inactive'"
                  counter
                ></v-switch>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" @click="goToEditPage(item.id)">
              mdi-pencil
            </v-icon>
          </template> -->
      <template v-slot:no-data>
        No documents found
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
import ArchiveSwitch from "./ArchiveSwitch.vue";
import moment from "moment";

export default {
  name: "DocumentsDataTable",
  components: {
    ArchiveSwitch
  },
  props: {
    docs: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    category: {
      type: String
    },
    subcategory: {
      type: String,
      default: null
    },
    route: {
      type: String
    }
  },
  data() {
    return {
      canEdit: false,
      user: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: "Submitted", value: "is_submitted" }
      ],
      archiveHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Date of upload",
          align: "start",
          sortable: true,
          value: "created_at"
        }
      ]
    };
  },
  async mounted() {
    this.$store.dispatch("documents/clearDocument");
    let user = await this.$admin.authProvider.checkAuth();
    if (this.$admin.can(["admin", "super_user"])) {
      this.canEdit = true;
      this.canDelete = true;
    } else {
      user.data.permissions.forEach(permission => {
        if (permission.name == "canDelete") {
          this.canEdit = true;
        } else if (permission.name == "canEdit") {
          this.canEdit = true;
        }
      });
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    goToOverview(currentFile) {
      if (!currentFile.document) {
        currentFile = { document: currentFile };
      }
      this.$store.dispatch("documents/setCurrentDocument", {
        document: currentFile,
        redirectBack: this.route
      });
      this.$router.push({
        path: "/document-vault/document-overview"
      });
    },
    async downloadFile(currentFile) {
      let documentName = null;
      if (!currentFile.isOther) {
        documentName = currentFile.document.file_path;
      } else {
        documentName = currentFile.file_path;
      }
      if (documentName) {
        documentName = documentName.split("documents/").pop();
        let headers = new Headers({ responseType: "blob" });
        await this.$admin.http
          .post(
            "/api/stored-documents",
            {
              filename: documentName
            },
            headers
          )
          .then(async response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = fileURL;
            link.download = documentName;
            link.click();
            URL.revokeObjectURL(link.href);
          });
      }
    },
    createDocument(item = null) {
      this.$store.dispatch("documents/setDocumentCategories", {
        categories: {
          category: this.category,
          subCategory: this.subcategory
        },
        requiredDoc: item,
        redirectBack: this.route
      });
      this.$router.push({
        path: "/document-vault/create-document"
      });
    }
  },
  computed: {
    isArchive: {
      get() {
        return this.$store.getters["documents/isArchive"];
      }
    }
  }
};
</script>

<template>
  <div class="pa-4" v-if="!loading">
    <h2 v-if="this.user">
      Hi {{ this.user.first_name ? this.user.first_name : this.user.name }},
      what would you like to do today?
    </h2>
    <cards-menu :links="links"></cards-menu>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import CardsMenu from "../components/CardsMenu.vue";
export default {
  components: { CardsMenu },
  data() {
    return {
      loading: false,
      links: [
        {
          title: "View impact statistics",
          src: require("../assets/statistics.png"),
          flex: 4,
          url: "/impact-zone"
        },
        {
          title: "View current board goals",
          src: require("../assets/filler.png"),
          flex: 4,
          url: "/board-goals"
        },
        {
          title: "Check board documents",
          src: require("../assets/documents.png"),
          flex: 4,
          url: "/documents"
        },
        {
          title: "Learn how to improve your scores",
          src: require("../assets/learn.jpg"),
          flex: 4,
          url: "/help-articles"
        }
      ]
    };
  },
  async created() {
    this.loading = true;
    let dashboardOrFormLink = {
      title: "Go to your dashboard",
      src: require("../assets/dashboard.png"),
      flex: 4,
      url: "/dashboard"
    };

    let profileLink = {
      title: "Edit your profile",
      src: require("../assets/profile.png"),
      flex: 4,
      url: "/profile"
    };

    this.user = (await this.$admin.authProvider.checkAuth()).data;
    const response = this.user.assessment_responses.pop();
    if (this.user.roles === "super_user" || this.user.roles === "admin") {
      profileLink = {
        title: "Go to your profile dashboard",
        src: require("../assets/profile.png"),
        flex: 4,
        url: "/profiles-dashboard"
      };
      this.links.push(profileLink);
      if (!response?.scores.length > 0) {
        dashboardOrFormLink = {
          title: "Take the ESG Assessment",
          src: require("../assets/fill-out-document.png"),
          flex: 4,
          url: "/assessment"
        };
      } else {
        this.links.push({
          title: "Retake the ESG Assessment",
          src: require("../assets/fill-out-document.png"),
          flex: 4,
          url: "/assessment"
        });
      }
      if (this.user.company.board.assessment_responses.length > 0) {
        this.links.push({
          title: "View your previously submitted assessments",
          src: require("../assets/filled-out-document.png"),
          flex: 4,
          url: "/submitted-assessments/"
        });
      }
    }
    this.links.unshift(dashboardOrFormLink);
    this.loading = false;
  },
  computed: {}
};
</script>

// /* eslint-disable import/no-cycle */
// import router from "../../router";

const documents = {
  namespaced: true,
  state: {
    document: {
      title: null,
      description: null,
      file: null,
      url: null,
      requiredDoc: null,
      categories: {
        category: null,
        subCategory: null
      }
    },
    documents: [],
    isArchive: false,
    requiredDocuments: {},
    errorMessages: {},
    message: null,
    redirectBack: "/document-vault"
  },
  mutations: {
    setDocumentCategories(state, documentCategories) {
      state.document.categories.category = documentCategories.category;
      if (documentCategories.subCategory) {
        state.document.categories.subCategory = documentCategories.subCategory;
      }
    },
    clearDocument(state) {
      state.document = {
        title: null,
        description: null,
        file: null,
        url: null,
        requiredDoc: null,
        categories: {
          category: null,
          subCategory: null
        }
      };
    },
    setCurrentDocument(state, document) {
      state.document = document;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    toggleArchive(state) {
      state.isArchive = !state.isArchive;
    },
    setRequiredDoc(state, requiredDoc) {
      state.document.requiredDoc = requiredDoc;
    },
    setRedirect(state, url) {
      state.redirectBack = url;
    },
    setErrorMessages(state, errors) {
      state.errorMessages = errors;
    },
    setMessage(state, message) {
      state.message = message;
    },
    clearErrorMessages(state) {
      state.errorMessages = {};
      state.message = null;
    }
  },
  actions: {
    setCurrentDocument({ commit }, parameters) {
      if (parameters.document) {
        commit("setCurrentDocument", parameters.document);
      }
      if (parameters.redirectBack) {
        commit("setRedirect", parameters.redirectBack);
      }
    },
    clearDocument({ commit }) {
      commit("clearDocument");
    },
    toggleArchive({ commit }) {
      commit("toggleArchive");
    },
    async setDocumentCategories({ commit }, parameters) {
      if (parameters.categories) {
        commit("setDocumentCategories", parameters.categories);
      }
      if (parameters.requiredDoc) {
        commit("setRequiredDoc", parameters.requiredDoc);
      }
      if (parameters.redirectBack) {
        commit("setRedirect", parameters.redirectBack);
      }
    },
    async getDocumentsForCategory({ commit, state }, parameters) {
      let tempCat = parameters.category;
      let tempDocs = tempCat.data.required_documents;
      if (!state.isArchive) {
        tempCat.data.documents.forEach(tempDoc => {
          if (tempDocs.length > 0) {
            tempDocs.forEach((tempRequiredDoc, indexRequiredDoc) => {
              if (
                tempDoc.required_document_id &&
                tempDoc.required_document_id === tempRequiredDoc.id
              ) {
                tempRequiredDoc.document = tempDoc;
                tempDocs[indexRequiredDoc] = tempRequiredDoc;
              } else if (
                !tempDoc.required_document_id &&
                tempDocs.indexOf(tempDoc) === -1
              ) {
                tempDoc.isOther = true;
                tempDocs.push(tempDoc);
              }
            });
          } else {
            tempDoc.isOther = true;
            tempDocs.push(tempDoc);
          }
        });
        commit("setDocuments", tempDocs);
      } else {
        commit("setDocuments", tempCat.data.documents);
      }
    },
    async openCreateDocumentForm({ commit }, parameters) {
      commit("openForm", parameters);
    }
  },
  getters: {
    currentDocument: state => state.document,
    documents: state => state.documents,
    isArchive: state => state.isArchive,
    redirectBack: state => state.redirectBack,
    errorMessages: state => state.errorMessages,
    message: state => state.message
  }
};
export default documents;

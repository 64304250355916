export default [
  { name: "users", icon: "mdi-account", permissions: ["admin"] },
  {
    name: "companies",
    icon: "mdi-earth",
    permissions: ["admin"],
    label: "name",
    actions: undefined
  },
  {
    name: "board-members",
    icon: "mdi-briefcase-account",
    label: "name",
    actions: undefined
  },
  {
    name: "board-goals",
    icon: "mdi-bullseye",
    label: "name",
    actions: undefined
  },
  // {
  //   name: "strategic-development-goals",
  //   icon: "mdi-bullseye-arrow",
  //   permissions: ["admin", { name: "user", actions: ["list", "show"] }],
  //   label: "description",
  //   actions: undefined
  // },
  {
    name: "sustainable-development-goals",
    icon: "mdi-bullseye-arrow",
    api: "strategic-development-goals",
    //permissions: ["admin"],
    permissions: ["admin", { name: "user", actions: ["list", "show"] }],
    label: "description",
    actions: undefined
  },
  {
    name: "pillars",
    icon: "mdi-pillar",
    label: "name",
    actions: null,
    permissions: []
  },
  // {
  //   name: "documents",
  //   icon: "mdi-file-document",
  //   label: "name",
  //   actions: undefined,
  //   except: ["show"]
  // },
  {
    name: "help-articles",
    icon: "mdi-help-box",
    permissions: ["admin"],
    label: "title"
  }
];

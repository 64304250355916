<template>
  <v-row :key="itemIndex" align="center" no-gutters class="task-list-item mb-2">
    <!--    Goal description-->
    <v-col cols="11" md="5" class="pb-2">
      <div class="font-weight-light" v-text="item.description" />
    </v-col>

    <!--    Goal status chip -->
    <v-col cols="6" md="2">
      <v-chip
        small
        class="mb-1 mr-1 text-white"
        color="#2D2D2C"
        v-text="item.status"
      >
      </v-chip>
    </v-col>
    <!--
        v-text="$t('resources.board-goals.enums.status.' + item.status)"
        --
      >
        > -->

    <!--    Board members chips -->
    <v-col cols="6" md="3">
      <span v-for="boardMember in item.board_members" :key="boardMember.id">
        <v-chip
          small
          class="mb-1 mr-1 text-white"
          color="#2D2D2C"
          v-text="boardMember.name"
        >
        </v-chip>
      </span>
    </v-col>

    <!--    SDG icons-->
    <v-col cols="12" md="1" class="pb-2">
      <div v-for="sdg in item.strategic_development_goals" :key="sdg.id">
        <a :href="'sustainable-development-goals/' + sdg.id">
          <img alt="SDG Icon" width="65" height="65" :src="sdg.logo_url" />
        </a>
      </div>
    </v-col>

    <v-col cols="1">
      <a :href="'board-goals/' + item.id + '/edit'">
        <v-icon> mdi-pencil </v-icon>
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BoardGoalItem",
  props: {
    itemIndex: {
      type: Number
    },
    item: {
      type: Object
    }
  }
};
</script>

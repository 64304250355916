<template>
  <va-create-layout>
    <board-members-form :title="title" :item="item"> </board-members-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>

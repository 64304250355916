<template>
  <v-form ref="form">
    <va-messages></va-messages>
    <v-alert v-if="message" type="error">{{ message }}</v-alert>

    <div class="text-left">
      <router-link class="text-body-4" :to="{ name: 'login' }">
        &lt; {{ $t("auth.sign_in") }}
      </router-link>
    </div>

    <v-text-field
      :label="$t('auth.email')"
      :rules="emailRules"
      prepend-icon="mdi-email"
      v-model="form.email"
      required
      :error-messages="errorMessages.email"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.password')"
      type="password"
      v-model="form.password"
      prepend-icon="mdi-lock"
      :rules="passwordRules"
      required
      :error-messages="errorMessages.password"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.password_confirmation')"
      type="password"
      prepend-icon="mdi-lock"
      :error-messages="errorMessages.password_confirmation"
      :rules="[
        form.password_confirmation === form.password || 'Passwords do not match'
      ]"
      v-model="form.password_confirmation"
      required
    ></v-text-field>

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        @click="saveForm"
        text
        rounded
      >
        {{ $t("auth.next") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    token: String
  },
  data() {
    return {
      dialog: false,
      form: {
        email: null,
        password: null,
        password_confirmation: null
      },
      passwordRules: [
        value => value !== null || "Password is required",
        value =>
          (value && value.length >= 8) ||
          "Password must be at least 8 characters"
      ],
      emailRules: [
        value => value !== null || "Email is required",
        value =>
          (value && value.length <= 40) ||
          "Email must be less than 40 characters",
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      ],
      loading: false,
      message: null,
      errorMessages: {}
    };
  },
  computed: {
    currentForm() {
      return this.$store.getters["forms/currentForm"];
    }
  },
  async mounted() {
    if (this.currentForm) {
      this.form = this.currentForm;
    }
  },
  methods: {
    async saveForm() {
      this.loading = true;
      try {
        await this.$admin.http.post("api/registration/validate", this.form);
        this.$store.dispatch("forms/saveFormData", this.form);
        if (!this.message) {
          this.$router.push({ name: "register_step_2" });
        }
      } catch (e) {
        this.$admin.toast.error(e.message);

        if (e.errors) {
          this.errorMessages = e.errors;
        }
        this.loading = false;
      }
      this.loading = false;
    }
  }
};
</script>

<template>
  <!--  Export settings-->
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :enableLinks="true"
    :html-to-pdf-options="{
      filename: 'Boards123 Assessment - ' + data.selectedPeriod.start_date
    }"
    :paginate-elements-by-height="1100"
    :pdf-quality="2"
    :margin="[5, 5]"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    :manual-pagination="false"
    @progress="onProgress($event)"
    @startPagination="startPagination()"
    @hasPaginated="hasPaginated()"
    @beforeDownload="beforeDownload($event)"
    @hasDownloaded="hasDownloaded($event)"
    ref="html2Pdf"
  >
    <!-- PDF Content starts here-->
    <section
      slot="pdf-content"
      style="font-family: Roboto,sans-serif!important;width: 100%;"
    >
      <!--      Company information and overall score-->
      <section slot="pdf-item" style="height: 100%">
        <v-col
          cols="12"
          class="accent"
          style="margin-top: 0px !important; padding-top: 0px!important;"
        >
          <v-row>
            <v-col cols="6">
              <p style="font-size: 0.9rem">
                Asessment period: {{ data.selectedPeriod.start_date }} -
                {{ data.selectedPeriod.end_date }}
              </p>
            </v-col>
            <v-col cols="6">
              <div
                v-if="data.overall_score"
                class="pt-5 px-4 pb-2 secondary mt-8 rounded rounded d-flex flex-column align-center"
                style="display: flex"
              >
                <img
                  src="../assets/logo.png"
                  width="70"
                  height="70"
                  style="margin-left: auto; position: absolute; right: 1rem; z-index: 99999"
                  alt=""
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <h2>
                Company
              </h2>

              <h1
                style="font-size: 2.5rem; margin-top: 1rem"
                class=" text-h2 font-weight-bold"
              >
                {{ data.name }}
              </h1>
              <p style=" margin-top: 0.5rem">
                Purpose Statement:
              </p>
              <p
                v-if="data.company_slogan"
                class="text-h3 mb-8 font-weight-light grey--text"
                style="margin-bottom: 0.2rem"
              >
                {{ data.company_slogan }}
              </p>
            </v-col>

            <v-col cols="12" md="6" v-if="data.overall_score">
              <div class="pa-8 mt-4">
                <h2 style="margin-bottom: 1rem">
                  Impact
                </h2>
                <v-row>
                  <p
                    class=""
                    style="    
                    width: 100%;
                    text-align: center;
                    font-size: 5.5rem;
                    left: 0;
                    bottom: 10px;
                    line-height: 100%;
                    font-weight: 600;"
                  >
                    {{ data.overall_score }}
                    <span
                      class="grey--text"
                      style="
                      font-size: 3rem;
                      font-weight: 400;
                      color: #9e9e9e !important;
                      caret-color: #9e9e9e !important;"
                      >/125</span
                    >
                  </p>
                </v-row>

                <v-row>
                  <v-col>
                    <p
                      style="    
                    width: 100%;
                    text-align: center;
                    font-size: 1.3em;
                    line-height: 100%;
                    margin-bottom: 1rem !important;
                    display: block;"
                    >
                      Board Maturity Level:
                      <span
                        v-if="data.overall_score <= 25"
                        class="grey--text scoreLabelSpan"
                        >{{ $t("dashboard.level_starter") }}</span
                      >
                      <span
                        v-if="
                          data.overall_score > 25 && data.overall_score <= 50
                        "
                        class="scoreLabelSpan"
                        >{{ $t("dashboard.level_developing") }}
                      </span>
                      <span
                        v-if="
                          data.overall_score > 50 && data.overall_score <= 75
                        "
                        class="scoreLabelSpan"
                        >{{ $t("dashboard.level_consistent") }}</span
                      >
                      <span
                        v-if="
                          data.overall_score > 75 && data.overall_score <= 100
                        "
                        class="scoreLabelSpan text-caption"
                        >{{ $t("dashboard.level_achieving") }}</span
                      >
                      <span
                        v-if="data.overall_score > 100"
                        class="scoreLabelSpan"
                        >{{ $t("dashboard.level_leading") }}</span
                      >
                    </p>

                    <p>
                      <span v-if="data.overall_score <= 25" class="grey--text">
                        {{ $t("dashboard.level_starter_desc") }}
                      </span>
                      <span
                        v-if="
                          data.overall_score > 25 && data.overall_score <= 50
                        "
                        class="grey--text"
                      >
                        {{ $t("dashboard.level_developing_desc") }}
                      </span>
                      <span
                        v-if="
                          data.overall_score > 50 && data.overall_score <= 75
                        "
                        class="grey--text"
                      >
                        {{ $t("dashboard.level_consistent_desc") }}
                      </span>
                      <span
                        v-if="
                          data.overall_score > 75 && data.overall_score <= 100
                        "
                        class="grey--text"
                      >
                        {{ $t("dashboard.level_achieving_desc") }}
                      </span>
                      <span v-if="data.overall_score > 100" class="grey--text">
                        {{ $t("dashboard.level_leading_desc") }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </section>

      <!--      Scores per pillar-->
      <section slot="pdf-item">
        <v-row>
          <v-col cols="6" class="scoreWithIcon mx-auto w-100">
            <div class="w-100 pa-3 pillarScore mx-2">
              <div class="w-100 mb-1rem">
                <div class="w-100 iconWrapper">
                  <v-icon
                    class="whiteIcon white white--text"
                    color="white"
                    size="32"
                    >mdi-account-supervisor-circle</v-icon
                  >
                </div>
                <div class="ml-1rem mb-2rem mt-1rem">
                  <div class="font-weight-light">
                    People Score
                  </div>

                  <h3 class="ml-3 ">{{ data.people_score.toString() }} / 25</h3>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="6" class="scoreWithIcon mx-auto w-100">
            <div class="w-100 pa-3 pillarScore">
              <div class="w-100 mb-1rem">
                <div class="w-100 iconWrapper">
                  <v-icon
                    class="whiteIcon white white--text"
                    color="white"
                    size="32"
                    >mdi-earth</v-icon
                  >
                </div>
                <div class="ml-1rem mb-2rem mt-1rem">
                  <div class="font-weight-light">
                    Environment Score
                  </div>

                  <h3 class="ml-3 ">
                    {{ data.environment_score.toString() }} / 25
                  </h3>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="6" class="scoreWithIcon w-100">
            <div class="w-100 pa-3 pillarScore mx-2">
              <div class="w-100 mb-1rem">
                <div class="w-100 iconWrapper">
                  <v-icon
                    class="whiteIcon white white--text"
                    color="white"
                    size="32"
                    >mdi-file-document-outline</v-icon
                  >
                </div>
                <div class="ml-1rem mb-2rem mt-1rem">
                  <div class="font-weight-light">
                    Governance Score
                  </div>

                  <h3 class="ml-3 ">{{ data.people_score.toString() }} / 25</h3>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="6" class="scoreWithIcon mx-auto w-100">
            <div class="w-100 pa-3 pillarScore mx-2">
              <div class="w-100 mb-1rem">
                <div class="w-100 iconWrapper">
                  <v-icon
                    class="whiteIcon white white--text"
                    color="white"
                    size="32"
                    >mdi-hand-heart-outline</v-icon
                  >
                </div>
                <div class="ml-1rem mb-2rem mt-1rem">
                  <div class="font-weight-light">
                    Social Impact Score
                  </div>

                  <h3 class="ml-3 ">
                    {{ data.environment_score.toString() }} / 25
                  </h3>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </section>

      <!--      Board goals-->
      <section slot="pdf-item">
        <h2 style="margin-left: 1rem; margin-bottom: 1rem">
          {{ $t("resources.board-goals.goals") }}
        </h2>
        <v-app class="pdfWrap">
          <v-data-table
            dense
            :headers="headers"
            :items="items[4].goals"
            item-key="description"
            class=""
            :items-per-page="5000"
            hide-default-footer
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                small
                class="mr-1"
                v-text="item.status"
                style="
                background-color: #2D2D2C;
                border-color: #2D2D2C;
                color:white!important;
                margin-top: 2px;
                margin-bottom: 2px"
              >
              </v-chip>
            </template>

            <template v-slot:item.board_members="{ item }">
              <v-chip
                :key="boardMember.id"
                v-for="boardMember in item.board_members"
                style="
                    color: white;
                    background-color: #2D2D2C;
                    border-color: #2D2D2C;
                    margin-top: 2px;
                    margin-bottom: 2px"
                v-text="boardMember.name"
              >
              </v-chip>
            </template>

            <template v-slot:item.strategic_development_goals="{ item }">
              <img
                v-for="sdg in item.strategic_development_goals"
                :key="sdg.id"
                :src="sdg.logo_url"
                width="50"
                height="50"
                alt=""
              />
            </template>
          </v-data-table>
        </v-app>
      </section>

      <!--      Footer -->
      <section slot="pdf-item">
        <div style="margin-top: 1rem; margin-bottom: 1px; font-size: 0.75rem">
          <hr />
          <p>This document was generated on: {{ $d(new Date(), "short") }}</p>
          <div>
            Boards123 helps Boards of Directors deliver purpose and impact.
            That's why we made this Dashboard Report. If you have any questions,
            please contact us at
            <a href="mailto:hello@boards123.com"> hello@boards123.com </a>. We
            are here to help.
          </div>
        </div>
      </section>
    </section>
    <!-- PDF Content ends here -->
  </vue-html2pdf>
</template>

<style lang="sass">
.ml-1rem
  margin-left: 1rem!important
.mb-1rem
  margin-bottom: 1rem!important
.mb-2rem
  margin-bottom: 2rem!important
.mt-1rem
  margin-top: 1rem !important

.scoreLabelSpan
  font-size: 1.5rem
  font-weight: 400
  /**/

.w-100
  width: 100%!important

.iconWrapper
  background-color: #2D2D2C !important
  border-color: #2D2D2C !important
  color: #2D2D2C
  border-radius: 8px

.whiteIcon
  color: white!important
  border-color: white!important

.scoreWithIcon .text-start
  background-color: #2D2D2C !important
  border-color: #2D2D2C !important
  color: #2D2D2C


.pdfWrap .v-application--wrap
  min-height: 0


.text-start span
  font-size: 1rem !important


.pillarScores
  width: 90% !important
</style>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "PdfExport",
  data: () => ({
    headers: [
      { text: "Description", sortable: false, value: "description" },
      {
        text: "Status",

        sortable: false,
        value: "status"
      },
      {
        text: "Board Members",
        value: "board_members",
        type: "referenceArray",
        sortable: false,
        link: "show",
        attributes: {
          reference: "board-members",
          text: "name",
          chip: true
        }
      },
      {
        text: "Strategic Development Goals",
        value: "strategic_development_goals",
        type: "referenceArray",
        sortable: false,
        link: "show",
        attributes: {
          reference: "sustainable-development-goals",
          text: "itemText",
          chip: true
        }
      }
    ]
  }),
  props: {
    data: {
      type: Object
    },
    items: {
      type: Array
    }
  },
  components: {
    VueHtml2pdf
  }
};
</script>

<template>
  <va-create-layout>
    <help-articles-form :title="title" :item="item"></help-articles-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>

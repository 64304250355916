<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-checkbox
          v-model="form.acceptTerms"
          :rules="[
            form.acceptTerms || 'You need to accept the terms and conditions'
          ]"
        >
          <template v-slot:label>
            <div>
              <a v-bind="attrs" v-on="on" href="#">{{ $t("auth.tandc") }}</a>
            </div>
          </template>
        </v-checkbox>
      </template>

      <v-card>
        <v-card-title class="text-h2 grey lighten-2 break-word">
          Legal terms for Boards123 Dashboard
        </v-card-title>

        <v-card-text>
          <p>
            These legal terms (“Terms”) are about the Boards123 Dashboard (the
            "Platform”) and apply to your use of it. These Terms are between you
            the customer and us/we Boards123 by Digital54 Ltd. Please read
            through these Terms. By accessing and using the Platform you agree
            to be legally bound by these Terms. If you wish not to be bound by
            these Terms, you should not access the Platform. You should save
            and/or print a copy of these Terms for future reference. We may
            update these terms from time to time to reflect changes in the
            services provided under the Platform, changes in the law or other
            changes that we feel are appropriate. If we change them, we will let
            you know, following which your continued use of any element of the
            Platform will indicate that you agree to the changes. PLEASE SEE THE
            VERY IMPORTANT PROVISIONS UNDER THE FOLLOWING HEADERS: WHAT WE ARE
            NOT RESPONSIBLE FOR LIABILITY
          </p>
          <h2 class="mt-1">
            Who can use the Platform?
          </h2>

          To be entitled to use the Platform you must be a Boards123 customer.
          All of your authorised users will have access to the Platform – so
          please ensure they are aware of these Terms and prevent any
          unauthorised access.
          <h2 class="mt-1">
            What is the Platform?
          </h2>
          It includes a paid Platform that provides the customer that set of ESG
          scoring data ESG Board Goals that we hold about you including the data
          visualisation of that data. We will continue to make enhancements to
          the Platform over time and introduce new services and features that
          may require a different fee to be paid to access it. You can choose
          whether to take out these services when they become available.
          <h2 class="mt-1">
            What we are not responsible for
          </h2>
          <p>
            We hope you find the Platform valuable. You should not however rely
            solely on the data we show you within the Platform in isolation but
            instead use other sources and your own experience, knowledge and
            skill to make decisions about your business. Nothing in the Platform
            contains investment or strategy advice nor should it be construed as
            a recommendation, endorsement or solicitation. Data and Open Data
            displayed within the Platform a is sourced from information entered
            into the apps by yourself or other users of the apps and supplied to
            us with your authorisation. We are not responsible for the content,
            accuracy or suitability of the data displayed, as derived from the
            information entered into the apps outside of Boards123 or received
            from Open Data providers. We take reasonable steps to ensure the
            information is accurately transferred from the Open Data API’s, but
            we do not guarantee that it is complete, accurate, up-to-date or
            error-free. We do not promise that any app you connect to the
            Platform will be of a particular quality, nor will we be responsible
            for the way a third party app provider behaves. The Platform is
            provided “as is” and “as available” and your use of it is at your
            own risk.
          </p>
          <h2 class="mt-1">
            Liability
          </h2>
          <p>
            The terms of your account with us explain when you cannot claim back
            if you suffer loss or damage in using the Platform or any app: as a
            result of the content, accuracy or suitability of the data displayed
            within the ESG Dashboard or Open Data API’s or derived from the
            information entered into the Dashboard outside of Boards123 by
            Digital54 Ltd. if you make any decision about your business based on
            the data shown within the Platform. if you can’t access the Platform
            for any reason or it’s slow. for any consequential or similar types
            of losses from timing delays. due to abnormal and unforeseeable
            circumstances outside our control, which meant we couldn’t follow
            this agreement despite our best efforts to do so – for example, a
            hardware breakdown, strike, a major problem with a payment system,
            or an act, omission or delay of a third party. if there was no way
            we could have reasonably predicted your loss. if you are claiming
            for loss of business, loss of goodwill, loss of opportunity or loss
            of profit. where you have been fraudulent or careless or where you
            are in breach of these Terms.
          </p>
          <h2 class="mt-1">
            Access
          </h2>
          <p>
            We may, from time to time, amend the Platform and/or content, as
            well as your access to it at our sole discretion and without giving
            notice to you. We may also suspend, withdraw, discontinue or change
            the Platform and your access to this service at any time and without
            notice. You can decide to stop using the Platform and deactivate it
            at any time by contacting us via the Help and Support section within
            the Platform or by clicking on manage connections and deactivate.
            Please bear in mind that this will remove all access for you and
            your other authorised users and the data will be deleted in line
            with the paragraph "Sharing and using your information" below. If
            you cease to be eligible or if you breach any of these Terms your
            Platform will be deactivated. If the Platform is deactivated, please
            note that any subscriptions or other contracts that you have with
            third parties will continue and their services will need to be paid
            for; these must be cancelled by you directly with the relevant third
            party.
          </p>
          <h2 class="mt-1">
            Things you must not do
          </h2>
          <p>
            You and your users may not do any of the following things: Permit
            any third party to access the Platform, other than in a manner set
            out in the FAQs or user guides, or to use the Platform to provide
            services to any third party; Circumvent or modify any security
            mechanisms used on the Platform or any app; Internal Only Replicate
            the functionality of or copy the Platform; Use the Platform or any
            app or data provided via the Platform in to store or transmit
            infringing, libellous, defamatory or otherwise unlawful or tortious
            material or in any way in furtherance of criminal, fraudulent, or
            other unlawful activity; Cause us to breach the rights of any third
            party, including intellectual property rights; Remove or destroy any
            copyright notices, proprietary markings or confidential legends
            within the Platform or any app; Upload any virus or malicious code
            to the Platform; Interfere with, disrupt, damage or access in any
            unauthorised manner the integrity or performance of the Platform,
            the servers, networks or other properties or services of Boards123
            by Digital54 Ltd or any third party, including not complying with
            any operational requirements that we notify to you, or the FAQs or
            user guides; or Use any automatic device, algorithm, or any
            comparable manual process, to access or monitor any portion of the
            Platform.
          </p>
          <h2 class="mt-1">
            Sharing and using your information
          </h2>
          <p>
            You can add materials and content to your Platform once you are on
            the Platform, including data you add to any app that you confirm you
            are happy to connect to the Platform. You confirm that you are
            allowed to add this additional data to the Platform. Once you have
            chosen to connect an app to the Platform we will be able to see how
            you use the Platform and the data that powers your Platform. In
            relation to certain data processing activities, we may be acting as
            a controller (as such term is defined under applicable data
            protection legislation) for certain information. We may use this
            information in line with your general terms and the Privacy Notice,
            including for the following purposes: so you can see a visual
            summary of your ESG scoring level. so you can see a visual summary
            of your ESG goals. so you can see a visual summary of your Board of
            Directors; Until you request to disconnect from the Platform, we
            will regularly retrieve the data from the data providers in the
            background (including when you are not logged in to the Platform) to
            improve your experience and ensure more accurate and up to date
            information is available. If you cease using the Platform, we will
            delete from the Platform all data that could be used to identify you
            or your business, but may retain some data in anonymised form.
            Nobody will be able to identify you or your business from this
            anonymised data. We will be able to view the data that is powering
            the Platform for as long as your Platform is activated. We may use
            this data, where relevant, to keep improving the services we offer
            to you and to tell you about products and services that may be of
            interest to you. If you disconnect an app or Open Data that was
            connected to the Platform, this will affect the way in which the
            Platform operates and may mean that certain parts of it will cease
            to function properly or at all. If you choose to deactivate any app
            or Open Data previously connected to the Platform, we will retain a
            record of your data from that app for a reasonable time period after
            deactivation for legitimate business purposes.
          </p>
          <h2 class="mt-1">
            Other Payment Service Provider Data
          </h2>
          <p>
            If you give your permission to connect any other payment service
            provider account, we’ll request information on your accounts,
            including sort code and account number to complete the
            transaction.You’ll need to confirm that you remain comfortable for
            the data to be shared with us every 90 days, although some payment
            service providers may ask you to confirm this more or less
            frequently. You’re only permitted to view or initiate payments from
            accounts that are held in your name or accounts over which you have
            appropriate authority, such as a Power of Attorney. The other
            payment service provider may refuse to share the data with us if you
            do not have appropriate authority over the account. If you choose to
            stop sharing your data with us or cancel your access to the Platform
            but are still a customer with us, we may continue to request the
            data from your other payment service provider for up to 90 days (the
            time duration might vary depending on your other payment service
            provider).
          </p>
          <h2 class="mt-1">
            Third Parties Data
          </h2>
          <p>
            Where you have disclosed or made available to us information about
            natural persons, including from your own processors: you will have
            done so in compliance with the applicable data protection
            legislation (and in a manner that will not cause us to be in breach
            of such legislation); you will have ensured that you have the legal
            right to disclose such of their personal data to us for the purpose
            of carrying out any action contemplated by this Agreement; and you
            will provide reasonable assistance to enable us to comply with our
            obligations under the applicable data protection legislation.
          </p>
          <h2 class="mt-1">
            Cookies Policy
          </h2>
          <p>
            By using the Platform you accept that cookies will be placed on your
            device when clicking anywhere on the webpage hosting the Platform.
            Your use of the Platform will be subject to our cookie policy.
          </p>
          <h2 class="mt-1">
            Queries
          </h2>
          <p>
            You can find more information, FAQs and user guides within Help and
            Support on the Platform. There is also a Platform support team that
            you can contact via Help and Support.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              dialog = false;
              form.acceptTerms = true;
            "
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TermsCheckbox",
  props: {
    dialog: {
      type: Boolean
    },
    form: {
      type: Object
    }
  }
};
</script>

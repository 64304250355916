<template>
  <div v-if="!loading">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <HelpButton class="mt-4"></HelpButton>
    <div class="mt-5">
      <DocumentsDataTable
        :docs="docs"
        :loading="loading"
        category="Onboarding Documents"
        route="onboarding-docs"
      ></DocumentsDataTable>
    </div>
  </div>
  <div class="d-flex" v-else>
    <h1 class="mx-auto">
      {{ $t("impact-zone.loading") }} <v-btn icon loading></v-btn>
    </h1>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
import DocumentsDataTable from "@/components/DocumentsDataTable.vue";
export default {
  components: {
    HelpButton,
    DocumentsDataTable
  },
  data() {
    return {
      loading: false,
      company: null,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          exact: true,
          to: "/"
        },
        {
          text: "Document Vault",
          disabled: false,
          exact: true,
          to: "/document-vault"
        },
        {
          text: "Onboarding Documents",
          disabled: true,
          to: "/document-vault/legal-docs"
        }
      ]
    };
  },
  async mounted() {
    this.loading = true;
    await this.getDocuments();
    this.loading = false;
  },
  computed: {
    docs: {
      get() {
        return this.$store.getters["documents/documents"];
      }
    },
    isArchive: {
      get() {
        return this.$store.getters["documents/isArchive"];
      }
    }
  },
  watch: {
    isArchive: {
      handler() {
        this.getDocuments();
      },
      immediate: true
    }
  },
  methods: {
    async getDocuments() {
      let tempCat = await this.$admin.http.post("/api/document-category", {
        category_name: "Onboarding Documents",
        is_archived: this.isArchive
      });
      this.$store.dispatch("documents/getDocumentsForCategory", {
        category: tempCat
      });
    }
  }
};
</script>

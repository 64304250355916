<template>
  <base-material-card :icon="resource.icon" :title="title" color="#2D2D2C">
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" disable-clone disableShow>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["name"],
      fields: [
        { source: "name", sortable: true },
        { source: "url", type: "url" }
      ]
    };
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        e.preventDefault();
      }
    });
  }
};
</script>

// /* eslint-disable import/no-cycle */
// import router from "../../router";

const forms = {
  namespaced: true,
  state: {
    form: null,
    errorMessages: {},
    message: null
  },
  mutations: {
    setFormData(state, formData) {
      state.form = formData;
    },
    setErrorMessages(state, errors) {
      state.errorMessages = errors;
    },
    setMessage(state, message) {
      state.message = message;
    },
    clearErrorMessages(state) {
      state.errorMessages = {};
      state.message = null;
    },
    clearFormData(state) {
      state.form = null;
    }
  },
  actions: {
    async saveFormData({ commit }, parameters) {
      commit("setFormData", parameters);
    },
    async clearFormData({ commit }) {
      commit("clearFormData");
    },
    async register({ commit, state }) {
      try {
        commit("clearErrorMessages");
        await this.$admin.http.post("/register", state.form);
        this.$admin.toast.success(this.$t("profile.successfully_created"));
        commit("clearFormData");
      } catch (e) {
        if (e.errors) {
          commit("setErrorMessages", e.errors);
          return;
        }
        commit("setMessage", e.message);
      }
    }
  },
  getters: {
    currentForm: state => state.form,
    errorMessages: state => state.errorMessages,
    message: state => state.message
  }
};
export default forms;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('HelpButton'),_c('div',{staticClass:"mt-5 w-50 mx-auto"},[_c('base-material-card',{attrs:{"icon":"mdi-file-document-alert","color":"#2D2D2C"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2"},[_c('div',{staticClass:"display-2"},[_vm._v(" "+_vm._s(_vm.$t("resources.documents.titles.create"))+" ")])])]},proxy:true}],null,false,2677944469)},[(_vm.currentDocument)?_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","rules":_vm.catRules,"label":"Select a category","return-object":"","single-line":""},on:{"change":function($event){return _vm.changeCategory()}},model:{value:(_vm.currentDocument.categories.category),callback:function ($$v) {_vm.$set(_vm.currentDocument.categories, "category", $$v)},expression:"currentDocument.categories.category"}}),(
            _vm.currentDocument.categories.category &&
              _vm.currentDocument.categories.category.sub_categories &&
              _vm.currentDocument.categories.category.sub_categories.length > 0
          )?_c('v-select',{attrs:{"items":_vm.currentDocument.categories.category.sub_categories,"item-text":"name","item-value":"id","rules":_vm.subCatRules,"label":"Select a sub-category","return-object":"","single-line":""},model:{value:(_vm.currentDocument.categories.subCategory),callback:function ($$v) {_vm.$set(_vm.currentDocument.categories, "subCategory", $$v)},expression:"currentDocument.categories.subCategory"}}):_vm._e(),(_vm.currentDocument.categories.subCategory)?_c('v-select',{attrs:{"items":_vm.currentDocument.categories.subCategory.required_documents,"item-text":"name","item-value":"id","rules":_vm.requiredDocRules,"label":"Select a document you'd like to upload","return-object":"","single-line":""},model:{value:(_vm.currentDocument.requiredDoc),callback:function ($$v) {_vm.$set(_vm.currentDocument, "requiredDoc", $$v)},expression:"currentDocument.requiredDoc"}}):(_vm.currentDocument.categories.category)?_c('v-select',{attrs:{"items":_vm.currentDocument.categories.category.required_documents,"item-text":"name","item-value":"id","rules":_vm.requiredDocRules,"label":"Select a document you'd like to upload","return-object":"","single-line":""},model:{value:(_vm.currentDocument.requiredDoc),callback:function ($$v) {_vm.$set(_vm.currentDocument, "requiredDoc", $$v)},expression:"currentDocument.requiredDoc"}}):_vm._e(),(
            _vm.currentDocument.requiredDoc &&
              _vm.currentDocument.requiredDoc.is_submitted
          )?_c('p',[_c('small',[_vm._v(" This document has already been submitted. Submitting it again would archive the old version. ")])]):_vm._e(),_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":"Name","error-messages":_vm.errorMessages.name,"required":""},model:{value:(_vm.currentDocument.name),callback:function ($$v) {_vm.$set(_vm.currentDocument, "name", $$v)},expression:"currentDocument.name"}}),_c('v-textarea',{attrs:{"label":"Description","rules":_vm.descriptionRules,"required":""},model:{value:(_vm.currentDocument.description),callback:function ($$v) {_vm.$set(_vm.currentDocument, "description", $$v)},expression:"currentDocument.description"}}),_c('v-text-field',{attrs:{"label":"Url","rules":_vm.currentDocument.file ? [] : _vm.urlRules,"required":""},model:{value:(_vm.currentDocument.url),callback:function ($$v) {_vm.$set(_vm.currentDocument, "url", $$v)},expression:"currentDocument.url"}}),_c('div',[_c('v-file-input',{attrs:{"rules":_vm.currentDocument.url ? [] : _vm.fileRules,"label":"Upload document"},model:{value:(_vm.currentDocument.file),callback:function ($$v) {_vm.$set(_vm.currentDocument, "file", $$v)},expression:"currentDocument.file"}})],1)],1):_vm._e(),_c('v-btn',{attrs:{"variant":"outlined","size":"x-large","icon":"","color":"#2D2D2C"},on:{"click":_vm.saveDocument}},[_c('v-icon',[_vm._v("mdi-floppy")])],1)],1)],1)],1):_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.$t("impact-zone.loading"))+" "),_c('v-btn',{attrs:{"icon":"","loading":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
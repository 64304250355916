<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card color="#2D2D2C">
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="description" required></va-text-input>
            <va-select-input
              source="pillar"
              reference="pillars"
            ></va-select-input>
            <va-text-input source="goal_url" required></va-text-input>
            <va-text-input source="color" required></va-text-input>
            <!--            <va-file-input-->
            <!--              source="goal_icon"-->
            <!--              preview-->
            <!--              src="thumbnails.large"-->
            <!--              contain-->
            <!--            ></va-file-input>-->
          </v-card-text>
          <va-save-button text="false" color="#2D2D2C"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"]
};
</script>

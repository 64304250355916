<template>
  <va-layout>
    <va-app-bar
      v-if="this.$route.name !== 'assessment'"
      slot="app-bar"
      :profile-menu="profileMenu"
      color="white"
      @toggle="
        $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
      "
    ></va-app-bar>
    <va-sidebar
      v-if="sidebarMenu && this.$route.name !== 'assessment'"
      slot="sidebar"
      :menu="sidebarMenu"
      v-model="drawer"
      :mini-variant="mini"
      dark
      color="#2D2D2C"
    ></va-sidebar>
    <template slot="header">
      <va-breadcrumbs
        v-if="
          this.$route.name !== 'assessment' &&
            !this.$route.path.includes('document-vault')
        "
      ></va-breadcrumbs>
      <impersonate-message></impersonate-message>
    </template>
    <va-aside slot="aside"></va-aside>
    <va-footer slot="footer" :menu="footerMenu">
      &copy; {{ new Date().getFullYear() }}
    </va-footer>
  </va-layout>
</template>

<script>
import ImpersonateMessage from "@/components/ImpersonateMessage";
import nav from "../_nav";

export default {
  name: "App",
  components: {
    ImpersonateMessage
  },
  data() {
    return {
      drawer: null,
      mini: false,
      headerMenu: [],
      companyName: null,
      footerMenu: [
        {
          href: "/terms-and-conditions",
          text: this.$t("menu.t&c")
        }
      ],
      profileMenu: [
        {
          icon: "mdi-account",
          text: this.$t("menu.profile"),
          link: "/profile"
        }
      ],
      sidebarMenu: null
    };
  },
  async created() {
    let user = await this.$admin.authProvider.checkAuth();
    this.companyName = user.data.company.name;

    this.sidebarMenu = nav(this.$i18n, this.$admin, this.companyName);
    if (user.data.roles === "super_user" || user.data.roles === "admin") {
      this.profileMenu = [
        {
          icon: "mdi-account",
          text: this.$t("menu.profiles-dashboard"),
          link: "/profiles-dashboard"
        }
      ];
    }
  }
};
</script>

<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <HelpButton class="mt-4"></HelpButton>
    <div class="mt-5">
      <v-row class="mx-auto">
        <v-col
          cols="4"
          md="4"
          lg="4"
          :key="index"
          v-for="(docCategory, index) in docCategories"
        >
          <div
            class="theme--dark primary-branding-color text-white elevation-6 pa-3 px-5 v-list-item v-list-item--link"
            @click="$router.push({ path: docCategory.url })"
          >
            <p class="text-white mb-0 ml-2">
              {{ docCategory.title }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import HelpButton from "@/components/buttons/HelpButton";
export default {
  components: {
    HelpButton
  },
  data() {
    return {
      docCategories: [
        {
          title: "Legal",
          is_submitted: false,
          url: "/document-vault/legal-docs"
        },
        {
          title: "Insurance",
          is_submitted: false,
          url: "/document-vault/insurance-docs"
        },
        {
          title: "Regulation",
          is_submitted: false,
          url: "/document-vault/regulation-docs"
        }
      ],
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          exact: true,
          to: "/"
        },
        {
          text: "Document Vault",
          disabled: false,
          exact: true,
          to: "/document-vault"
        },
        {
          text: "Legal, Insurance & Regulation Documents",
          disabled: true,
          to: "/document-vault/legal-insurance-regulation-docs"
        }
      ]
    };
  },
  // async mounted() {
  //   this.loading = true;
  //   this.loading = false;
  // },
  methods: {}
};
</script>

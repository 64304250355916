import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import admin from "./plugins/admin";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./sass/overrides.sass";
import "./sass/site.sass";
import store from "./store";
import moment from "moment";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  admin,
  moment,
  render: h => h(App)
}).$mount("#app");

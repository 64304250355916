<template>
  <v-row
    align="center"
    no-gutters
    class="task-list-item board-goal-headers mb-2"
  >
    <!--    Goal description-->
    <v-col cols="11" md="5" class="pb-2">
      <div class="font-weight-light">
        Description
      </div>
    </v-col>

    <!--    Goal status chip -->
    <v-col cols="6" md="2">
      <div class="font-weight-light">
        Status
      </div>
    </v-col>
    <!--
        v-text="$t('resources.board-goals.enums.status.' + item.status)"
        --
      >
        > -->

    <!--    Board members chips -->
    <v-col cols="6" md="3">
      <div class="font-weight-light">
        Board Members
      </div>
    </v-col>

    <!--    SDG icons-->
    <v-col cols="12" md="1" class="pb-2">
      <div class="font-weight-light">
        SDGs
      </div>
    </v-col>

    <v-col cols="1"> </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BoardGoalHeaders",
  props: {}
};
</script>

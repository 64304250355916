import Vue from "vue";
import Vuex from "vuex";
import forms from "./modules/forms";
import documents from "./modules/documents";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    forms,
    documents
  }
});
